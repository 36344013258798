import React from 'react';
import {Row, Col} from 'reactstrap';
import './style.scss';
import MMarineLogo from "../../../styles/assets/images/menu/m-marine.webp";
import SVLogo from "../../../styles/assets/images/sv/sv_logo.svg";
import WRLogoBlack from "../../../styles/assets/images/menu/WRLogoBlack.svg";
import HeaderMenu from "../../common/HeaderMenu";
import {BASE_SILAVETRA_URL} from "../../../lib/api/globalState";
import {t} from "i18next";
import {useApi} from "../../../lib/api/useApi";
import {Link} from "react-router-dom";
import {isRus} from "../../../lib/utils/country";

const Header = ({label, isMMarine=false}) => {
    const { getGlobalState } = useApi();
    const globalState = getGlobalState();
    const isRoleTablet = globalState.clientRole === 'tablet';
    const logo = isRus ? SVLogo : WRLogoBlack

    return (
    <Row className='header justify-content-between justify-content-sm-start align-items-center no-gutters'>
       <>
            <Col xs="auto" sm="9">
                <Row className="flex-column flex-sm-row align-items-center">
                    {isMMarine ?
                        <Col xs="auto" sm="5" lg="6" className={`header__mmarine`}>
                            <span className="header__logo d-flex justify-content-center">
                                <a href="https://m-marine.ru/" target="_blank"><img src={MMarineLogo} alt="" /></a>
                            </span>
                        </Col>
                    :
                        <Col xs="12" sm="5" lg="6" className="">
                            <span className="header__logo">
                                {isRoleTablet ?
                                    <Link to="/tablet">
                                        <img src={logo} alt="" />
                                    </Link>
                                :
                                    <a href={BASE_SILAVETRA_URL} target="_blank">
                                        <img src={logo} alt="" />
                                    </a>
                                }
                            </span>
                        </Col>
                    }
                    <Col xs={isMMarine ? "auto" : 12} sm="5" lg="6"
                         className={`mt-3 mt-sm-0 d-flex align-items-center justify-content-center`}
                    >
                        {isRoleTablet ?
                            <Link to="/tablet" className="header__link">
                                <span className={'header__step'}>{t(label)}</span>
                            </Link>
                            : <span className={'header__step'}>{t(label)}</span>
                        }
                    </Col>
                </Row>
            </Col>
            <Col xs="3" className="pl-0 pl-sm-4">
                <div className="d-flex justify-content-end">
                    <HeaderMenu isMMarine={isMMarine} />
                </div>
            </Col>
        </>
    </Row>
  )
}

export default Header;