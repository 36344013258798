import React, { FC, useCallback, useMemo } from 'react';
import { Button } from 'reactstrap';
import PaymentPayExtraComponent from '../components/payExtra';
import { t } from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { TOrder } from '../../../../lib/utils/types';
import { useInjection } from 'brandi-react';
import { calculateSubscriptionBalance } from '../../../../lib/utils/helpers';
import { PayBlockModelStoreToken } from '../models/PayBlockModel/index.model';

interface IPaymentMethodAbonementComponentProps {
  order: TOrder,
}

const PaymentMethodAbonementComponent: FC<IPaymentMethodAbonementComponentProps> = (props) => {
  const {
    order,
  } = props;

  const payBlockModel = useInjection(PayBlockModelStoreToken);

  const history = useHistory();
  const { pathname } = useLocation();

  const onBuy = useCallback(() => {
    history.push(`/abonement?redirectURL=${pathname}&redirectOrderId=${order?.id}`);
  }, []);

  const subBalance = useMemo(() => calculateSubscriptionBalance(payBlockModel.subscription.value?.subscriptionCurrentValue, payBlockModel.subscription.value?.discountedPrice, true), [payBlockModel.subscription.value]);
  const success = useMemo(() => subBalance >= 0, [subBalance]);

  if (order?.hasPaid) {
    return null;
  }

  return (
    <div className='payment-block'>
      <div className='title-label'>{t('paymentPay.subscription')}</div>
      {payBlockModel.subscription.value && payBlockModel.subscription.value.subscriptionCurrentValue > 0 ?
        <>
          <table className='mt-3'>
            <tbody>
            <tr>
              <td className='font-weight-bold pb-2 pr-5'>{t('subscriptions.balance')}:</td>
              <td className='pb-2'>{payBlockModel.subscription.value?.subscriptionCurrentValue} {t('paymentPay.bonusCount')}</td>
            </tr>
            <tr>
              <td className='font-weight-bold pb-2 pr-5'>{t('common.discount')}:</td>
              <td className='pb-2'>{payBlockModel.subscription.value?.discountValue}%</td>
            </tr>
            <tr>
              <td className='font-weight-bold pb-2 pr-5'>{t('paymentPay.remainder')}:</td>
              <td className={`pb-2 ${success ? 'text-success' : 'text-danger'}`} >{subBalance || 0} {success && t('paymentPay.bonusCount')} {!success && t('paymentPay.notEnoughBonus')}</td>
            </tr>
            </tbody>
          </table>
        </>
      : <div className='d-flex align-items-center'>
          <span>{t('paymentPay.noneActiveSub')}</span>
          <Button
            color={'primary'}
            type={'button'}
            className={'form-button mt-0'}
            onClick={onBuy}
          >
            {t('common.buy')}
          </Button>
        </div>}
      {!success && <>
        <div className='payment-divider ml-m5' />
        <PaymentPayExtraComponent />
      </>}
    </div>
  )
}

export default PaymentMethodAbonementComponent;