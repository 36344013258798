import React, { useEffect, useState } from "react";
import {Col, Row, Input, Label} from 'reactstrap';
import { useForm } from "react-hook-form";
import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import './style.scss'
import {regexes} from '../../../lib/utils/helpers';
import {useHistory, useLocation} from "react-router-dom";
import FormButton from "../../generic/buttons/main/index2";
import SubscriptionInfo from './SubscriptionsInfo'
import _ from 'lodash'
import {useCaptcha} from "../../../lib/utils/hooks/useCaptcha";
import {features} from "../../../lib/api/globalState";
import PriceBlock from "../PriceBlock";
// @ts-ignore
import {ERROR_MESSAGE_LANG_ADAPTER} from "sv-common/constants/certificates";
import {TSubscription, useTypedPublicEndpoints} from "../../../lib/api/useTypedPublicEndpoint";
import {useOrdersApi} from "../../../lib/api/useOrdersApi";
import {usePaymentsApi} from "../../../lib/api/usePaymentsApi";
import {TCertificate, useCertificatesApi} from "../../../lib/api/useCertificatesApi";
import useCountry from "../../../lib/utils/hooks/useCountry";
import {ICurrency, TOrder} from "../../../lib/utils/types";
import {useLoaderState} from "../../../lib/api/loaderState";
import external from "../../../styles/assets/images/sv/external.svg";
import i18next from "i18next";
import Divider from "../../generic/Divider";
import SubscriptionRange from "./SubscriptionRange";
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import { useInjection } from 'brandi-react';
import { AuthModelStoreToken } from '../../../entities/auth/model';

type TSubscriptionForm = {
    setSubscription: (s: TSubscription ) => void,
    certificateApplied: TCertificate,
    setCertificateApplied: (cb: (c: TCertificate) => void) => void,
    email: string,
    subscription: TSubscription,
    price: number,
    userSubscriptionId: number,
    currencies: [ICurrency],
    entity: {[key: string]: any},
    order: TOrder,
    depositSum: number
}

type TSubscriptionFormWithCountry = TSubscriptionForm & {country: string}

const SubscriptionFormWithCountry = (props: TSubscriptionForm) => {
    const country = useCountry();
    return <SubscriptionForm {...props} country={country}/>
}

const SubscriptionForm = ({
                              setSubscription, certificateApplied, setCertificateApplied, country,
                              email, subscription, price, userSubscriptionId, currencies, entity, order, depositSum
}: TSubscriptionFormWithCountry ) => {
    const {t, language} = i18next;

    const authM = useInjection(AuthModelStoreToken);
    const { checkCertificate } = useCertificatesApi();
    const {getSubscriptions} = useTypedPublicEndpoints();
    const {createOrder} = useOrdersApi();
    const {createPayment} = usePaymentsApi();
    // @ts-ignore
    const { pathname, query } = useLocation();
    const history = useHistory();

    const [responseError, setResponseError] = useState<string>();
    const [subscriptions, setSubscriptions] = useState<TSubscription[] | undefined>(undefined);
    const [allSubscriptions, setAllSubscriptions] = useState<TSubscription[] | null>(null);
    const [currentSubscriptionData, setCurrentSubscriptionData] = useState<{[key: string]: any } | undefined>({})
    const [certificateName, setCertificateName] = useState<string | null>(null)
    const [certificateStatus, setCertificateStatus] = useState<string | null>(null)

    const {setIsLoading} = useLoaderState();

    useEffect(() => {
        setIsLoading(true);
        getSubscriptions()
            .then(res => {
                const {subscriptions} = res;
                const subscriptionsFiltered = subscriptions.filter(subscription => subscription.is_available_for_sale)

                setSubscriptions(subscriptionsFiltered);

                setAllSubscriptions(subscriptions)
                if (price) {
                    const chosenSubscription = subscriptionsFiltered.find(item => +item.price_value === +price)
                    if (!chosenSubscription) return;
                    setSubscription(chosenSubscription);
                } else {
                    setSubscription(subscriptionsFiltered[0])
                }
            })
            .finally(() => setIsLoading(false))
    }, [country])

    const { register, errors, handleSubmit} = useForm({ mode: "onBlur", reValidateMode: "onChange" });

    const onCertificateButtonClick = async (captcha: string) => {
        const {status, ...certificate} = await checkCertificate(certificateName?.trim() || '', captcha, 'subscriptions', subscription.id)

        if (status === 'valid') setCertificateApplied(() => certificate)
        else setCertificateApplied(() => null)

        setCertificateStatus(status);
    }

    const [generateCaptcha, CaptchaDiv] = useCaptcha(onCertificateButtonClick)

    const paymentHandler = (orderId: number) => {
        const redirectURL = query.redirectURL ? `&redirectURL=${query.redirectURL}` : '';
        const redirectOrderId = query.redirectURL ? `&redirectOrderId=${query.redirectOrderId}` : '';
        const paymentQueryParams = `?orderId=${orderId}&step=2${redirectURL}${redirectOrderId}`;

        createPayment(orderId, pathname + paymentQueryParams)
            .then((res) => (window.location.href = res.url))
            .catch((e) => {
                console.log(e);
            })
    };

    const onCreate = (data: {email: string}) => {
        authM.ensureLoggedIn(() => {
            setIsLoading(true)
            const participant = {email: data.email};
            const payloadData = {
                participants: [participant],
                product: 'subscriptions',
                productId: subscription.id,
                certificateApplied: certificateApplied?.id
            };
            return createOrder(payloadData)
                .then(res => {
                    if (features['SV-1569']) {
                        history.push('/error')
                    } else {
                        paymentHandler(res.orderId)
                    }
                }).catch(res => {
                    setResponseError(res.response.data.message)
                })
                .finally(() => setIsLoading(false))
        })
    }

    useEffect(() => {
        allSubscriptions && setCurrentSubscriptionData(allSubscriptions.find( subscription => subscription.id === userSubscriptionId ))
    }, [subscriptions, userSubscriptionId])

    return (
        // @ts-ignore
		<div className="subscription">
            {CaptchaDiv}
			<p className="subscription-title">{t('subscriptions.title')}</p>
			<form onSubmit={handleSubmit(onCreate)}>
                <Row>
                <Col xs={12} md={6} className={'control-wrapper'}>
                    <span className={'input-label'}>
                        {t('subscriptions.countryOfUsage')}:
                    </span>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                      <select id='country' defaultValue={'RU'} value={country} disabled>
                        <option value="RU">Россия</option>
                      </select>
                    </ElementWithAccessInCountries>
                    <ElementWithAccessInCountries hideInCountries={['RU']}>
                      <select id='country' defaultValue={'EN'} value={country} disabled>
                        <option value="EN">UAE</option>
                      </select>
                    </ElementWithAccessInCountries>
                </Col>
                <Col xs={12} md={6} className={'control-wrapper'}>
                    <span className={'input-label'}>
                        {t('subscriptions.ownerEmail')}
                    </span>
                    {(errors[`email`]) && <span><img src={errors[`email`] ? invalid : valid} alt="" /></span>}

                    <Input type="text" id={'email'} name={'email'} placeholder="username@email.com"
                        defaultValue={email}
                        innerRef={register({ required: true, pattern: regexes.emailRegex })}
                    />

                    <span className={"error-label d-none"}>
                    </span>
                </Col>
                </Row>
                <SubscriptionRange
                    subscriptions={subscriptions}
                    subscription={subscription}
                    setSubscription={setSubscription}
                    register={register}
                    currencies={currencies}
                    renderSlider={({handleChange, min, max}) =>
                        <Input
                            type="range"
                            id={'price'}
                            value={subscription?.price_value}
                            name={'value'}
                            min={min}
                            max={max}
                            step={1}
                            onChange={(e) => handleChange(+e.target.value)}
                            innerRef={register && register({ required: false })}
                />} />
                {!_.isEmpty(currentSubscriptionData) &&
                    <SubscriptionInfo
                        currentSubscriptionData={currentSubscriptionData}
                        subscription={subscription}
                        currencies={currencies}
                        depositSum={depositSum}
                    />
                }
                <div className="checkout__input-wrapper promocode-input">
                    <div className="title-label">{t('subscriptions.payWithCert')}</div>
                    <div className="certificate_info_wrapper flex-sm-nowrap flex-wrap d-flex justify-content-between align-items-start">
                        <div className="certificate_input">
                            <Input
                                type="text"
                                id="certificate"
                                className={""}
                                placeholder={t('subscriptions.enterCert') as string}
                                name={'certificate'}
                                value={certificateName || ''}
                                onChange={e => setCertificateName(() => e.target.value)}
                            />
                            <FormButton className="small-button mb-3" value={t('inputs.enterPromocode.submitButton')} type={'button'} // @ts-ignore
                                        onClick={() => generateCaptcha()} disabled={!certificateName}/>
                        </div>
                        {certificateApplied && <div className="certificate_info">
                            <div className="certificate_balance d-flex flex-wrap justify-content-start justify-content-sm-end mb-1">
                                {t('subscriptions.balance')}:&nbsp;
                                <span className="certificate_balance_value">
                                    <span className='certificate_balance_old'>{certificateApplied.sizeLeft}</span>
                                </span>
                            </div>
                            <div className="certificate_balance d-flex flex-wrap justify-content-start justify-content-sm-end">
                                {t('subscriptions.debit')}:&nbsp;
                                <span className="certificate_balance_value">
                                    <span className='certificate_balance_new'>
                                        {certificateApplied.actualAbsoluteSize}
                                    </span>
                                </span>
                            </div>
                        </div>}
                    </div>
                    <div className="certificateError">
                        {ERROR_MESSAGE_LANG_ADAPTER[language][certificateStatus]}
                    </div>
                    <div className="price-block ">
                        <PriceBlock order={order} certificateApplied={certificateApplied} entity={entity}
                                    currencies={currencies}
                        />
                    </div>
                </div>
                <Divider />
                <div className="checkout__input-wrapper radio-input">
                    <Input
                        type="checkbox"
                        id="publicOfferConsent"
                        name="publicOfferConsent"
                        innerRef={register({
                            required: true,
                        })}
                    />
                    <Label htmlFor="publicOfferConsent">
                        <a href={t('subscriptions.publicOffer') as string} target="_blank" rel="noopener noreferrer">
                            {t('inputs.consents.publicOffer')}&nbsp;<img src={external} alt="" />
                        </a>
                    </Label>
                    <span className={errors.publicOfferConsent ? "error-label" : "error-label d-none"}>
                        {errors.publicOfferConsent?.type === "required" && t("inputs.consents.publicOfferError")}
                    </span>
                </div>
                <div className="d-flex align-items-center flex-column">
                    <FormButton value={t('common.buy')} type="submit" />
                    {responseError && <small className="text-danger">{responseError}</small>}
                </div>
            </form>
		</div>
	);
};

export default SubscriptionFormWithCountry;
