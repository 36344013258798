import { makeAutoObservable } from 'mobx';
import { ICertificate, IPromocode } from '../../../../../lib/utils/types';
import { TSubscriptionOptions } from '../../../../../lib/api/useTypedPublicEndpoint';
import { IPaymentDiscountModel } from '../../components/models/PaymentDiscount.interface';
import { IPayBlockModel } from './index.interface';
import { PaymentPayMethods, PaymentPayMethodsObject } from '../../constants';
import { injected, token } from 'brandi';
import container from '../../../../../lib/ioc';
import { PaymentModelStoreToken } from '../../components/models/PaymentDiscount.model';

export default class PayBlockModel implements IPayBlockModel {
  private currentMethod: typeof PaymentPayMethods = PaymentPayMethodsObject.CARD;

  isPaidLocally = false;
  payExtraMethod?: string;

  constructor(
    readonly cert: IPaymentDiscountModel<ICertificate>,
    readonly promo: IPaymentDiscountModel<IPromocode>,
    readonly subscription: IPaymentDiscountModel<TSubscriptionOptions>,
  ) {
    makeAutoObservable(this, {}, {autoBind: true});
  }

  verifyPromoAndCert = (service: (value: string, type: string) => void) => {
    this.cert.setError('');
    this.promo.setError('');
    this.cert.query && service(this.cert.query, 'certificate');
    this.promo.query && service(this.promo.query, 'promocode');
  }

  resetAppliedDiscounts = (type: string, isAll?: boolean) => {
    if (type === 'promocodeApplied' || isAll) {
      this.promo.clear();
    }
    if (type === 'certificateApplied' || isAll) {
      this.cert.clear();
    }
  }

  setPayExtraMethod = (method: string) => {
    this.payExtraMethod = method;
  }

  setIsPaidLocally = (value: boolean) => {
    this.isPaidLocally = value;
  }

  isSuccessApply = () => !this.cert.error && !this.promo.error && !!this.cert.value;

  isCardPay = () => {
    return (this.payExtraMethod === PaymentPayMethodsObject.CARD) || (this.currentMethod === PaymentPayMethodsObject.CARD);
  }

  isCashPay = () => {
    return (this.payExtraMethod === PaymentPayMethodsObject.CASH) || (this.currentMethod === PaymentPayMethodsObject.CASH);
  }

  setCurrentMethod = (method: typeof PaymentPayMethods) => {
    this.currentMethod = method;
  }

  getCurrentMethod = () => {
    return this.currentMethod;
  };
}

export const PayBlockModelStoreToken = token<IPayBlockModel>('PayBlockModelStoreToken');

container.bind(PayBlockModelStoreToken).toInstance(PayBlockModel).inSingletonScope();

injected(PayBlockModel, PaymentModelStoreToken, PaymentModelStoreToken, PaymentModelStoreToken);