import {Input, Label} from "reactstrap";
import {t} from "i18next";
import React, { FC } from 'react';
import { IRenderCheckboxes } from '../../../sport/MembersScreen/types';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';

//@ts-ignore
const AgeConsent: FC<IRenderCheckboxes> = ({dataContainer, register, errors, values}) => {
    return <>
        {dataContainer?.slot?.check_age_required && <div className="checkout__input-wrapper radio-input">
            <Input
                    type="checkbox"
                    id="ageConsent"
                    name="ageConsent"
                    innerRef={register({
                        required: true,
                    })}
            />
            <Label htmlFor="ageConsent">
                {t('inputs.consents.checkAge', {checkAgeValue: dataContainer?.slot?.check_age_value})}
            </Label>
            <span className={errors.ageConsent ? "error-label errorText__ageConsent" : "error-label errorText__ageConsent d-none"}>
              {errors.ageConsent?.type === "required"
                      ?
                <>
                  <ElementWithAccessInCountries hideInCountries={['EN']}>
                    <>
                      {t('inputs.consents.pleaseCheckAgeValue', {checkAgeValue: dataContainer?.slot?.check_age_value})}
                      <a href="https://silavetra.com/kids">{t('inputs.consents.childrenClub')}</a>
                      {t('inputs.consents.checkAgeSecondPart')}
                    </>
                  </ElementWithAccessInCountries>
                  <ElementWithAccessInCountries hideInCountries={['RU']}>
                    <>
                      {t('inputs.consents.pleaseCheckAgeValueEN', {checkAgeValue: dataContainer?.slot?.check_age_value})}
                    </>
                  </ElementWithAccessInCountries>
                </>
                      : ""}
          </span>
            { !values.ageConsent && !errors.ageConsent &&
                    <span className="defaultText__ageConsent " >
                  {t('inputs.consents.ifHaveNoAge', {checkAgeValue: dataContainer?.slot?.check_age_value})}
                        <a href="https://silavetra.com/kids">{t('inputs.consents.childrenClub')}</a>
                        {t('inputs.consents.checkAgeSecondPart')}
              </span>
            }
        </div>}
    </>
}

export default AgeConsent;