// eslint-disable-next-line
import React, {useContext} from 'react';
import {toast} from 'react-toastify';
import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import {emptyGlobalState, GlobalState} from "./globalState";
import { eraseCookie, getCookie, setCookie } from '../utils/cookies';
import {useHistory} from "react-router-dom";
import {parseLoginResponse} from "../utils/helpers/apiHeplers";
import {t} from "i18next";
import { IS_RUSSIA } from '../utils/constants';
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from '../models/global';

const http = rateLimit(axios.create(), {maxRequests: 6, perMilliseconds: 1000});
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:1337";
export const generateDownloadLink = (entity, data) => `${API_URL}/${entity}/download/${data.id}/${data.filename}`;
export const API_ADDRESS_SUGGESTIONS_URL = API_URL + '/public/address/suggestions';
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
export const NODE_ENV = process.env.NODE_ENV

const translateError = err => {
    switch (err) {
        case 'Not Found':
            return t('api.useApi.notFound')
        default:
            return err;
    }

}


export const useApi = () => {
    let history = useHistory();
    const globalStateM = useInjection(GlobalModelStoreToken);

    function errorHandler(error) {
        if (error.response) {
            // Internal server error
            if (error.response.status === 500) {
                toast('Something went wrong. Try to change data or return later', {type: 'error'})
                throw error;
            }

            if (error.response.status === 404) {
                if (history.location.pathname.includes('/school'))
                    history.push({
                        pathname: history.location.pathname.split('/').slice(0, 3).join('/'),
                        search: "?people=1&step=1"
                    })
                else
                    history.push('/')
                throw error;
            }

            if (error.response.data && error.response.data.message === 'Invalid token.') {
                logout();
                throw error;
            }

            // Forbidden, Not Found.
            if (error.response.data) {
                const msg = error.response.data.message || error.response.data
                toast(translateError(msg), {type: 'error'})
                throw error;
            }

            // Usual errors
            if (error.response.data?.errors) {
                const {data: {errors}} = error.response.data
                for (const e in errors)
                    errors[e].forEach(msg => toast(msg, {type: 'error'}))
                throw error;
            }
        }
        toast('Something wrong with request. Try again later', {type: 'error'})
        throw error;
    }

    const [state, setState] = useContext(GlobalState);

    const updateAccessToken = async () => {
        if(!state.userRefreshToken) return state;

        return http.post(
            `${API_URL}/auth/refreshToken`,
            {token: state.userRefreshToken},
            {},
        )
            .then((response) => {
                const newState = {
                    ...state,
                    userAccessToken: response.data.jwt,
                    userRefreshToken: response.data.refresh
                };

                setState(newState);

                return newState;
            })
            .catch((error) => {
                setState(emptyGlobalState);
                toast('Произошла ошибка при авторизации! Попробуйте перезайти.', { type: 'error' });
                throw error;
            });
    }

    function setUserName({first_name, last_name, gdpr_consent}) {
        return request(
            'post',
            `/public/user/${state.userId}/client`,
            {first_name, last_name, gdpr_consent}
        )
    }

    function authThroughSocialMedia(providerName, location) {
        return http.get(
            `${API_URL}/auth/${providerName}/callback${location.search}`,
        )
            .then((response) => {
                parseLoginResponse(response.data, state, setState)
                return response
            })
            .catch((error) => {
                toast('Something went wrong! Try again!', {type: 'error'});
                throw error;
            });
    }

    function generateSocialMediaLink(providerName) {
        return `${API_URL}/connect/${providerName}?redirect=${window.location.href}`
    }


    function forgotPassword(email, isMMarine) {
        return http.post(
            `${API_URL}/public/forgot-password`,
            {email, isMMarine},
        )
            .catch((error) => {
                toast('Something went wrong! Try again!', {type: 'error'});
                throw error;
            });
    }

    function resetPassword(code, password) {
        return http.post(
            `${API_URL}/public/reset-password`,
            {code, password, passwordConfirmation: password}
        )
            .then(res => parseLoginResponse(res.data, state, setState))
            .catch((error) => {
                toast('Something went wrong! Try again!', {type: 'error'});
                throw error;
            });
    }

    function authorize(identifier, password, captcha, eventType) {
        return http.post(
            `${API_URL}/public/auth`,
            {identifier, password, captcha, eventType}
        )
            .then(res => parseLoginResponse(res.data, state, setState))
            .catch((error) => {
                setState(emptyGlobalState);
                toast('Something went wrong! Try again!', {type: 'error'});
                throw error;
            });
    }

    function reg(email, password, consent, name, phone) {
        return http.post(
            `${API_URL}/public/reg`,
            {email, password, consent, name, phone}
        )
            .then(res => parseLoginResponse(res.data, state, setState))
            .catch((error) => {
                setState(emptyGlobalState);
                toast('Something went wrong! Try again!', {type: 'error'});
                throw error;
            });
    }

    // URL should start with '/'
    // Does NOT check user identity
    function rawRequest(method, url, data = {}) {
        const methodLower = method.toLowerCase();
        return http({
            method: method.toLowerCase(),
            url: `${API_URL}${url}`,
            params: methodLower === 'get' ? data : {},
            data: methodLower === 'get' ? {} : data,
        })
            .then((response) => response.data)
            .catch(errorHandler);
    }

    // URL should start with '/'
    // DOES check user identity
    /**
     * @param method
     * @param url
     * @param data
     * @param {object | null} stateToUse
     * @param isRetry
     * @return {Promise<T>}
     */
    function request(method, url, data = {}, stateToUse = null, isRetry = false) {
        const globalState = globalStateM.get();
        const theState = stateToUse || state || globalState;
        const methodLower = method.toLowerCase();
        const headers = {};
        if ((theState?.userAccessToken || globalState.userAccessToken)) headers.Authorization = `Bearer ${(theState.userAccessToken || globalState.userAccessToken)}`;

        return http({
            method: methodLower,
            url: `${API_URL}${url}`,
            params: methodLower === 'get' ? data : {},
            data: methodLower === 'get' ? {} : data,
            headers,
        })
            .then((response) => response.data)
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    if (!isRetry) {
                        return updateAccessToken()
                            .then((newState) =>
                                request(method, url, data, {...newState}, true)
                            );
                    } else {
                        logout();
                    }
                }
                throw error;
            })
            .catch(errorHandler);
    }

    function logout() {
        setState(emptyGlobalState);
        eraseCookie('hasCarrotSync');
    }

    function getGlobalState() {
        return state;
    }

    function updateGlobalState(data) {
        setState({
            ...state,
            ...data,
        });
    }

    function reloadUser() {
        const cookie = getCookie();
        const userData = {
            userAccessToken: cookie.SV_LOGIN,
            userRefreshToken: cookie.sv_booking_refresh_token,
            userEmail: cookie.sv_booking_email,
            userName: cookie.sv_booking_name,
            userId: cookie.sv_booking_uid,
            userRole: cookie.sv_booking_role
        }

        updateGlobalState({
            ...userData,
            isLoginPopupOpen: false
        });

        return userData;
    }

    function initCarrotUser() {
      return request('get', `/public/carrot/authorize`)
        .then(res => {
          if (res.hash && res.clientId) {
            setCookie('hasCarrotSync', res.hash);

            return window[IS_RUSSIA ? 'carrotquest' : 'dashly'].auth(res.clientId, res.hash);
          }
          return Promise.reject('No hash received')
        });
    }

    return {
        logout,

        reg,
        request,
        authorize,
        rawRequest,
        resetPassword,
        forgotPassword,
        getGlobalState,
        setUserName,
        updateGlobalState,
        authThroughSocialMedia,
        generateSocialMediaLink,
        reloadUser,
        updateAccessToken,
        initCarrotUser,

        materials: state.materials,
        levels: state.levels,
        currencies: state.currencies,
        userId: state.userId,
        userEmail: state.userEmail,
        userName: state.userName,
        userLoggedIn: !!state.userId && !!state.userAccessToken,
        userAccessToken: state.userAccessToken,
        userRole: state.userRole
    };
};
