import React, { FC, useCallback, useEffect, useState } from 'react';
import { PaymentPayMethodsObject } from './constants';
import getPaymentExtraBlock from './paymentMethods';
import Select, { SingleValue, StylesConfig } from 'react-select';
import './styles.scss';
// @ts-ignore
import getCountryValue from '../../../lib/utils/getCountryValue';
import { t } from 'i18next';
import {
  ICertificate,
  IOptionProps,
  IPromocode,
  TDiscount,
  TOrder,
  TPromoAndCertDiscount,
} from '../../../lib/utils/types';
import { useOrder } from '../../../lib/utils/hooks/useOrder';
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
import { TSubscriptionOptions } from '../../../lib/api/useTypedPublicEndpoint';
import { IPayBlockModel } from './models/PayBlockModel/index.interface';
import { PayBlockModelStoreToken } from './models/PayBlockModel/index.model';

const selectStyles: StylesConfig<IOptionProps> = {
  option: (styles, { isFocused }) => ({ ...styles, backgroundColor: isFocused ? '#ccc' : '#fff' }),
};

const selectClassNames = {
  control: () => 'bordered-input react-select-control',
  indicatorsContainer: () => 'none',
  valueContainer: () => 'block p-0',
  input: () => 'opacity-0',
  menuList: () => 'react-select-menu-list',
  menu: () => 'react-select-menu',
  option: () => 'react-select-option',
  placeholder: () => 'placeholder',
}

interface IPayBlockProps {
  productType: string,
  order: TOrder,
  setOrder: (value: TOrder) => void,
  setCurrentData: (value: any) => void,
  additionalDiscount?: TDiscount | undefined,
  productKey: string,
  setPromocodeApplied: (value?: IPromocode) => void,
  setCertificateApplied: (value?: ICertificate) => void,
  subscriptionData?: TSubscriptionOptions,
  isShowSubscription: boolean,
  isShowPromocode: boolean,
  setIsSubscriptionPay?: (value: boolean) => void,
  promoAndCertDiscount: TPromoAndCertDiscount,
}

const setInvalidReason = (reason: string, type: string, payBlockModel: IPayBlockModel) => {
  if (reason) {
    if (type === 'promocode') {
      payBlockModel.promo.setError(t('inputs.enterPromocode.wrongPromo'));
    }

    if (type === 'certificate') {
      payBlockModel.cert.setError(t('inputs.enterPromocode.wrongCert'));
    }
  }
}

const PayBlock: FC<IPayBlockProps> = observer((props) => {
  const {
    productType,
    order,
    setOrder,
    setCurrentData,
    additionalDiscount,
    productKey,
    setPromocodeApplied,
    setCertificateApplied,
    subscriptionData,
    isShowSubscription,
    isShowPromocode,
    setIsSubscriptionPay,
    promoAndCertDiscount,
  } = props;

  const product = order?.[productKey];
  const certOption = { value: PaymentPayMethodsObject.CERT, label: t('paymentPay.methods.cert') };
  const cashOption = { value: PaymentPayMethodsObject.CASH, label: t('paymentPay.methods.cash') };
  const cardOption = { value: PaymentPayMethodsObject.CARD, label: t('paymentPay.methods.card') };
  const subOption = { value: PaymentPayMethodsObject.SUB, label: t('paymentPay.methods.sub') };

  const { setPromocodeOrCertificate } = useOrder();

  const payBlockModel = useInjection(PayBlockModelStoreToken);

  const [selectValue, setSelectValue] = useState<IOptionProps>();
  const [methodExtraBlock, setMethodExtraBlock] = useState(payBlockModel.getCurrentMethod());
  const [options, setOptions] = useState<IOptionProps[]>([cardOption]);

  const ExtraBlock = getPaymentExtraBlock(methodExtraBlock);

  const setInitialState = useCallback((option: IOptionProps) => {
    setMethodExtraBlock(option.value);
    setSelectValue(option);
    payBlockModel.resetAppliedDiscounts('', true);
  }, []);

  const setPromoApplied = useCallback((value: IPromocode) => {
    payBlockModel.promo.setValue(value);
    payBlockModel.promo.setQuery(value.name);
    setPromocodeApplied(value);
  }, [payBlockModel.promo]);

  const setCertApplied = useCallback((value: ICertificate) => {
    payBlockModel.cert.setValue(value);
    payBlockModel.cert.setQuery(value.name);
    setCertificateApplied(value);
  }, [payBlockModel.cert]);

  const verifyPromocode = useCallback((value: string, type?: string) => {
    if (product) {
      setPromocodeOrCertificate(productType, value, product.id, order?.id, setCurrentData,
        setPromoApplied, setCertApplied, setOrder, additionalDiscount, (reason: string, type: string) => setInvalidReason(reason, type, payBlockModel),
        undefined, payBlockModel.resetAppliedDiscounts, type);
    }
  }, [product, productType, additionalDiscount, payBlockModel]);

  const onChangePayment = useCallback((option: SingleValue<IOptionProps>) => {
    if (option) {
      setMethodExtraBlock(option.value);
      setSelectValue(option);
      payBlockModel.resetAppliedDiscounts('', true);
      payBlockModel.setCurrentMethod(option.value);
    }

    setPromocodeApplied(undefined);
    setCertificateApplied(undefined);
    payBlockModel.resetAppliedDiscounts('', true);
    payBlockModel.setPayExtraMethod('');
  }, [payBlockModel]);

  const onSetOptions = (option: {value: string, label: string}) => {
    if (!options.some(i => i.value === option.value)) {
      setOptions(prevOptions => ([ ...prevOptions, option ]));
    }
  }

  useEffect(() => {
    isShowSubscription && onSetOptions(subOption);
  }, [isShowSubscription]);

  useEffect(() => {
    subscriptionData && subscriptionData.subscriptionCurrentValue > 0 && payBlockModel.subscription.setValue(subscriptionData);
  }, [subscriptionData]);

  useEffect(() => {
    if (methodExtraBlock === PaymentPayMethodsObject.SUB && subscriptionData && subscriptionData.subscriptionCurrentValue) {
      setIsSubscriptionPay?.(true);
    } else {
      setIsSubscriptionPay?.(false);
    }

    if (methodExtraBlock === PaymentPayMethodsObject.CARD) {
      payBlockModel.setPayExtraMethod(PaymentPayMethodsObject.CARD);
    }
  }, [methodExtraBlock]);

  useEffect(() => {
    if (order) {
      if (order.certificateApplied) {
        setInitialState(certOption);
        return;
      }

      if (order.paidLocally) {
        setInitialState(cashOption);
        return;
      }

      if (order.subscriptionAppliedId) {
        setInitialState(subOption);
        return;
      }

      setInitialState(cardOption);
    }
  }, [order]);

  useEffect(() => {
    if (order?.id) {
      order?.promocodeApplied && verifyPromocode(order.promocodeApplied, product.id);
      order?.certificateApplied && verifyPromocode(order.certificateApplied, product.id);

      if (product.paid_locally) {
        const extraOption = getCountryValue({ en: cashOption });

        extraOption && onSetOptions(extraOption);
        payBlockModel.setIsPaidLocally(true);
      }

      if (!product.disable_promo) {
        onSetOptions(certOption);
      }
    }
  }, [order?.id]);

  return (
    <div data-testid='payblock' className="checkout__input-wrapper sport_subscription_pay">
      <div className='payment-methods-block'>
        <div className='title-label'>{t('paymentPay.selectMethod')}</div>
        <Select<IOptionProps>
          data-testid='payment-select'
          className='select'
          classNames={selectClassNames}
          value={selectValue}
          options={options}
          onChange={onChangePayment}
          styles={selectStyles}
          isDisabled={order?.hasPaid}
        />
        <ExtraBlock
          promoAndCertDiscount={promoAndCertDiscount}
          order={order}
          verifyPromocode={verifyPromocode}
          setPromocodeApplied={setPromocodeApplied}
          setCertificateApplied={setCertificateApplied}
          isShowPromocode={isShowPromocode}
        />
      </div>
    </div>
  )
})

export default PayBlock;