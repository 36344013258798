import {useApi} from "./useApi";

export const PracticesApi = (isMMarine) => {
    const { rawRequest } = useApi();

    const getPractices = async () =>
        rawRequest('get', '/public/practices');

    const getPracticesByAdventureUrl = async (url) =>
        rawRequest('get', `/public/practices/${url}`);

    const getPracticeByUrl = async (url) =>
        rawRequest('get', `/public/practice/${url}`);

    const getPracticesMMarine = async () =>
        rawRequest('get', '/public/practices?mmarine=true');

    const getPracticesByAdventureUrlMMarine = async (url) =>
        rawRequest('get', `/public/practices/${url}?mmarine=true`);

    const getPracticeByUrlMMarine = async (url) =>
        rawRequest('get', `/public/practice/${url}?mmarine=true`);

    return {
        getPractices: isMMarine ? getPracticesMMarine : getPractices,
        getPracticesByAdventureUrl: isMMarine ? getPracticesByAdventureUrlMMarine : getPracticesByAdventureUrl,
        getPracticeByUrl: isMMarine ? getPracticeByUrlMMarine : getPracticeByUrl,
    }
}
