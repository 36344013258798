import { injected, token } from 'brandi';
import { IApiModel } from '../../../lib/api/index.interface';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';

export interface IPromoService {
  check: (productType: string, productId: number, name: string, orderId: number) => Promise<{ status: string, extraInfo: string, reason: string }>
}

export default class PromoService implements IPromoService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  check(productType: string, productId: number, name: string, orderId: number): Promise<{ status: string, extraInfo: string, reason: string }> {
    return this.api.request('get', `/public/promocode/valid?productType=${productType}&productId=${productId}&name=${name}&orderId=${orderId}`);
  }
}

export const PromoServiceStoreToken = token<IPromoService>('PromoServiceStoreToken');

container.bind(PromoServiceStoreToken).toInstance(PromoService).inSingletonScope();

injected(PromoService, ApiModelStoreToken);