import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import { Input, Label } from 'reactstrap';
import { regexes } from '../../../lib/utils/helpers';
import PropTypes from 'prop-types'
import './style.scss'
import { handleInputChange } from '../../../lib/utils/handleInputChange';

const Field = ({
    type,
    label,
    error,
    value,
    setValue,
    placeholder,
    fieldName,
    register,
    required = false,
    isEmailField = false,
    disabled = false,
    orientation,
    inputStyles,
}) => {
    let defaultFieldStyles = {};

    if (orientation === 'horizontal') {
      defaultFieldStyles = {
        ...defaultFieldStyles,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }
    }

    return (
        <div className="field" style={defaultFieldStyles}>
            <Label className="mt-2 input-label" htmlFor={fieldName}>
                {label}:
                {(error || value) && <span className="validation-img"><img src={error ? invalid : valid} alt="" /></span>}
            </Label>

                <Input
                    placeholder={placeholder}
                    id={fieldName}
                    name={fieldName}
                    value={!register && value ? value : undefined}
                    defaultValue={register && value ? value : undefined}
                    disabled={disabled}
                    onChange={e => {
                      setValue && setValue(handleInputChange(e).value);
                    }}
                    type={type || "text"}
                    innerRef={register && register({
                        required,
                        pattern: isEmailField && regexes.emailRegex
                    })}
                    style={inputStyles}
                />

            <div>
                <span className={error ? "error-label" : "error-label d-none"}>
                    {error?.type === "required" && "Поле обязательно для заполнения"}
                    {isEmailField && error?.type === "pattern" && "Введите существующий адрес email"}
                </span>
            </div>
        </div>
    )

}

Field.propTypes = {
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    isEmailField: PropTypes.bool,
    isBirthdateField: PropTypes.bool,
    isGenderField: PropTypes.bool,
}

export default Field