import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'
import MMarineLogo from './../../../styles/assets/images/menu/m-marine.svg'
import { Link } from 'react-router-dom';

const MMarineMenu = () => {
    return (
        <div className='mmarine-menu'>
            <div className="mmarine-menu__header">
                <div className="mmarine-menu__logo">
                    <img src={MMarineLogo} alt="" />
                </div>
                <h1 className="mmarine-menu__title">Бронирование</h1>
                <div className="mmarine-menu__personal"></div>
            </div>
            <div className="mmarine-menu__grid">
                <Link to='/m/school/theory/' className="mmarine-menu__link mmarine-menu__link--sport">
                    <div className="mmarine-menu__item">Теория</div>
                </Link>
                <Link to='/m/school/practice/' className="mmarine-menu__link mmarine-menu__link--travel">
                    <div className="mmarine-menu__item">Практика</div>
                </Link>
                <Link to='/m/school/lectures/' className="mmarine-menu__link mmarine-menu__link--school">
                    <div className="mmarine-menu__item">Лекторий</div>
                </Link>
            </div>
        </div>
    );
};

MMarineMenu.propTypes = {
    
};

export default MMarineMenu;
