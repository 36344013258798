import { Input, Label } from 'reactstrap';
import external from '../../../styles/assets/images/sv/external.svg';
import React, { FC } from 'react';
import i18next from 'i18next';
import { IRenderCheckboxes } from '../../sport/MembersScreen/types';

interface IPolicyConsentProps {
  url: string,
}

const PolicyConsent: FC<IRenderCheckboxes & IPolicyConsentProps> = ({register, values, errors, url}) => {
  const {t, language} = i18next;

  return <div className="checkout__input-wrapper radio-input">
    <Input
      type="checkbox"
      id="returnPolicyConsent"
      defaultChecked={false}
      name="returnPolicyConsent"
      innerRef={register({
        required: true,
      })}
    />
    <Label htmlFor="returnPolicyConsent">
      <a href={`${url}`} target="_blank" rel="noopener noreferrer">
        {t('inputs.consents.returnPolicy')}
        {t('inputs.consents.returnPolicyTrain')}
        <img src={external} alt="" />
      </a>
    </Label>
    <span className={errors.returnPolicyConsent ? "error-label" : "error-label d-none"}>
      {errors.returnPolicyConsent?.type === "required"
        ? t('inputs.consents.returnPolicyError')
        : ""}
    </span>
  </div>
}

export default PolicyConsent;