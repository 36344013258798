import dayjs, { Dayjs } from 'dayjs';
import { GoogleCLType, ICalendarEventsProps, OutlookCLType, TObjectHandler, YandexCLType } from './types';
import { Button } from 'reactstrap';
import React from 'react';
import './styles.scss';

const getFormattedDayJs = (date: string, time: string) => dayjs(`${date} ${time}`.trim(), time ? 'YYYY-MM-DD HH-mm-ss': 'YYYY-MM-DD');
const makeFormat = (data: Dayjs, format: string) => data.format(format);

const google: TObjectHandler = (name, start, end) => <Button tag='a' className="social-btn social-google" target="_blank" href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${name}&dates=${start}/${end}`} />;

const outlook: TObjectHandler = (name, start, end) => <Button tag='a' className="social-btn social-outlook" target="_blank" href={`https://outlook.office.com/calendar/0/deeplink/compose?enddt=${end}&path=/calendar/action/compose&startdt=${start}&subject=${name}`} />;

const yandex: TObjectHandler = (name, start, end) => <Button tag='a' className="social-btn social-yandex" target="_blank" href={`https://calendar.yandex.ru/event/0?name=${name}&startTs=${start}&endTs=${end}`} />;

const calendarObjectHandler: { [key: string]: TObjectHandler } = {
  google,
  outlook,
  yandex,
}

const calendarObjectFormat: { [key: string]: string } = {
  [GoogleCLType]: 'YYYYMMDDTHHmmss',
  [OutlookCLType]: 'YYYY-MM-DDTHH:mm:ss',
  [YandexCLType]: 'YYYY-MM-DDTHH:mm:ss',
}

const calendarLinkHandler = ({ startDate, endDate, startTime, endTime, name }: ICalendarEventsProps, type: string) => {
  const starDateDayJs = getFormattedDayJs(startDate, startTime);
  const endDateDayJs = getFormattedDayJs(endDate, endTime);

  const startDateFormat = makeFormat(starDateDayJs, calendarObjectFormat[type]);
  const endDateFormat = makeFormat(endDateDayJs, calendarObjectFormat[type]);

  return calendarObjectHandler[type](name, startDateFormat, endDateFormat);
}

export default calendarLinkHandler;