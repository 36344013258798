import {Price} from "sv-common";
import currency from "currency.js";
import PropTypes from 'prop-types';
import {normalizeCurrency} from "../../../lib/utils/helpers";
import {isRus} from "../../../lib/utils/country";
import { t } from 'i18next';

const PriceBlock = ({order, certificateApplied, entity, currencies}) => {
    const certificatePrice = Price.math.subtract(entity?.price_value, certificateApplied?.sizeLeft);
    const priceReminder = certificatePrice < 0 ? 0 : certificatePrice
    const paidEn = isRus ? '': 'paid_en'

    return (
        <div className={order?.hasPaid ? `price-info paid ${paidEn}` : "price-info"}>
            <div className="price-left-col">
                <div className="price-label card-label">{t('subscriptions.price')}</div>
                <div className="price-row">
                    {certificateApplied && <div className="old-price">
                        {`${currency(entity?.price_value, {separator: ' ', precision: 0, symbol: ''})
                            .format()} ${normalizeCurrency(entity?.price_currency, currencies) || ''}`}
                    </div>
                    }
                    <div className="price">
                        {
                            `${certificateApplied ? priceReminder : (entity?.price_value || '')}
                             ${normalizeCurrency(entity?.price_currency, currencies) || ''}`
                        }
                    </div>
                </div>
                <div className="price-discount-label">{certificateApplied && t('subscriptions.certificateDiscount')}</div>
            </div>
        </div>
    )
}


PriceBlock.propTypes = {
    order: PropTypes.object,
    entity: PropTypes.object,
    currencies: PropTypes.array,
    certificateApplied: PropTypes.oneOfType([
        PropTypes.instanceOf(null),
        PropTypes.object,
    ]),
}
export default PriceBlock