import './style.scss'
import Field from '../../generic/Field'
import {useForm} from "react-hook-form";
import PhoneField from '../../common/PhoneField'
import {useState, useEffect} from 'react'
import { Col, Container, Label, Row } from 'reactstrap';
import FormButton from '../../generic/buttons/main';
import SelectField from '../../generic/SelectField'
import dayjs from "dayjs";
import {usePublicEndpoints} from '../../../lib/api/usePublicEndpoints';
import {useApi} from '../../../lib/api/useApi';
import {dateToISO} from "../../../lib/utils/format";
import BirthdateRHF from "../../generic/BirthdateRHF";
import {localizeDate} from "../../../lib/utils/helpers";
import GenderRHF from "../../generic/GenderRHF";
import {t} from "i18next";
import TwfaModal from './TwfaModal';
import { isRus } from '../../../lib/utils/country';
import PrivateFileComponent from '../../common/PrivateFile';
import { Constants } from 'sv-common';
import getCountryValue from "../../../lib/utils/getCountryValue";

const getExperienceData = () => {
  const defaultData = [
    {
      label: t('profile.data.experience0'),
      value: '0/7'
    },
    {
      label: t('profile.data.experience1'),
      value: '1/7'
    },
    {
      label: t('profile.data.experience2'),
      value: '2/7'
    },
  ]

  if (isRus) {
    defaultData.push({
        label: t('profile.data.experience3'),
        value: '3/7'
      },
      {
        label: t('profile.data.experience4'),
        value: '4/7'
      },
      {
        label: t('profile.data.experience5'),
        value: '5/7'
      },
      {
        label: t('profile.data.experience6'),
        value: '6/7'
      },
      {
        label: t('profile.data.experience7'),
        value: '7/7'
      });
  }

  return defaultData;
}

const PersonalData = ({userData}) => {
    const {register, errors, handleSubmit, control, setError, reset, formState: { dirtyFields },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange"
    })
    // fields values
    const [phone, setPhone] = useState(userData?.phone)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [enName, setEnName] = useState('')
    const [enSurname, setEnSurname] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [gender, setGender] = useState('')
    const [pass, setPass] = useState('')
    const [citizenship, setCitizenship] = useState('')
    const [sailingExperience, setSailingExperience] = useState('')
    const [isSuccessfulUpdate, setIsSuccessfulUpdate] = useState(false);
    const [isUnsubscribedNews, setIsUnsubscribedNews] = useState(false);

    const [minPhoneLength, setMinPhoneLength] = useState('')
    const [isPhoneTouched, setIsPhoneTouched] = useState('');
    const [ordersStatistic, setOrdersStatistic] = useState({});

    const {updateClient, getOrdersStatistic} = usePublicEndpoints();
    const {getGlobalState} = useApi();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [presaveValues, setPresaveValues] = useState();

    useEffect(() => {
      getOrdersStatistic().then(setOrdersStatistic);
    }, []);

    useEffect(() => {
        setPhone(userData?.phone)
        setName(userData?.first_name)
        setSurname(userData?.last_name)
        setEmail(userData?.email)
        setEnName(userData?.first_name_en)
        setEnSurname(userData?.last_name_en)
        setGender(userData?.gender)
        setPass(userData?.passport_num_foreign)
        setCitizenship(userData?.citizenship)
        setSailingExperience(userData?.sailing_experience);
        setIsUnsubscribedNews(userData?.unsubscribed_news);
        const dataToReset = {
            phone: userData?.phone,
            name: userData?.first_name,
            surname: userData?.last_name,
            email: userData?.email,
            enName: userData?.first_name_en,
            enSurname: userData?.last_name_en,
            gender: userData?.gender,
            pass: userData?.passport_num_foreign,
            citizenship:  userData?.citizenship,
            sailingExperience: userData?.sailing_experience,
            unsubscribed_news: userData?.unsubscribed_news,
        }
        if (userData.birthdate) {
            setBirthdate(dayjs(userData?.birthdate).format('DD.MM.YYYY'))
            dataToReset.birthdate = localizeDate(userData?.birthdate);
        }
        reset(dataToReset);
    }, [userData])


    const handleUpdate = (userData) => {
        const dataToSend = {...userData};
        for (let k in dataToSend) {
            if (!dirtyFields[k])
                delete dataToSend[k]
        }

        if (dataToSend.birthdate) dataToSend.birthdate = dateToISO(dataToSend.birthdate);
        if (!dataToSend.gender) delete dataToSend.gender;
        const globalState = getGlobalState();

        return updateClient(globalState.userId, { ...dataToSend, code: userData.code, email: userData.email })
          .then(res => {
            if (res.status === 'updated') {
              setIsSuccessfulUpdate(true);
              setIsModalOpen(false);
            }
          })
          .catch(e => {
            if (e.response?.data?.message === 'Телефон уже занят') {
              setError('phone', {type: 'validate', message: t('profile.data.phoneTaken')})
            } else {
              throw e;
            }
          });
    }

    const travelText = `${String.fromCharCode(160)}(${t('profile.data.for')}${String.fromCharCode(160)}${t('profile.data.travel')})`;

    const forTravelText = getCountryValue({ ru: travelText })

    return (
        <Container>
            <form
              onSubmit={(e) => {
                handleSubmit((val) => {
                  setPresaveValues(val);
                  setIsModalOpen(true);
                })(e);
                e.preventDefault();
              }}
              className="personalData_form">
                <Row>
                    <Col xs="12" lg="6" className="personalData_block">
                        <h4 className="block_title">{t('profile.data.blockTitle')}</h4>
                        <div className="fields">
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.nameLabel')}
                                        value={name}
                                        setValue={setName}
                                        error={errors['name']}
                                        placeholder={t('profile.data.namePlaceholder')}
                                        fieldName="name"
                                        register={register}
                                        required
                                        className="field"
                                    />
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.surnameLabel')}
                                        value={surname}
                                        setValue={setSurname}
                                        error={errors['surname']}
                                        placeholder={t('profile.data.surnamePlaceholder')}
                                        fieldName="surname"
                                        register={register}
                                        required
                                        className="field"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <div className="field phone_field">
                                        <PhoneField
                                            label={t('profile.data.phoneLabel')}
                                            phoneValue={phone}
                                            setPhoneValue={e => setPhone(e.target.value)}
                                            control={control}
                                        />
                                        <span className={
                                            (phone?.length < minPhoneLength && isPhoneTouched)
                                            || (phone?.length < minPhoneLength && errors['phone'])
                                            || (errors['phone'])
                                                ? "error-label"
                                                : "error-label d-none"

                                        }>
                                            {errors['phone']?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.emailLabel')}
                                        value={email}
                                        setValue={setEmail}
                                        error={errors['email']}
                                        placeholder="username@email.com"
                                        fieldName="email"
                                        register={register}
                                        required
                                        isEmailField
                                        className="field"
                                    />
                                </Col>
                                <Col sm="6">
                                    <div className="field">
                                        <SelectField
                                            label={t('profile.data.experienceLabel')}
                                            error={errors['sailingExperience']}
                                            value={sailingExperience}
                                            setValue={setSailingExperience}
                                            fieldName="sailingExperience"
                                            register={register}
                                            defaultValue={t('profile.data.experienceDefaultLabel')}
                                            options={getExperienceData()}
                                        />
                                    </div>
                                </Col>
                                <Col sm="12">
                                  <div className="field">
                                    <p>{t('orders.statistic.count')}:</p>
                                    <p className='m-0'>{t('orders.statistic.slots')}: {ordersStatistic.slots_count}</p>
                                    <p className='m-0'>{t('orders.statistic.arrivals')}: {ordersStatistic.arrivals_count}</p>
                                    <p className='m-0'>{t('orders.statistic.groups')}: {ordersStatistic.groups_count}</p>
                                  </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs="12" lg="6" className="personalData_block">
                        <h4 className="block_title">
                            {t('profile.data.passportInternational')} {forTravelText}</h4>
                        <div className="fields">
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.enNameLabel')}
                                        value={enName}
                                        setValue={setEnName}
                                        error={errors['enName']}
                                        placeholder="Alex"
                                        fieldName="enName"
                                        register={register}
                                        className="field"
                                    />
                                </Col>
                                <Col sm="6">
                                    <BirthdateRHF value={birthdate} setValue={setBirthdate} control={control}
                                                  error={errors['birthdate']}/>
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.enSurnameLabel')}
                                        value={enSurname}
                                        setValue={setEnSurname}
                                        error={errors['enSurname']}
                                        placeholder="Thomson"
                                        fieldName="enSurname"
                                        register={register}
                                        className="field"
                                    />
                                </Col>
                                <Col sm="6">
                                    <GenderRHF
                                      control={control}
                                      value={gender}
                                      register={register}
                                      setValue={setGender}
                                      error={errors['gender']}
                                    />
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.passport')}
                                        value={pass}
                                        setValue={setPass}
                                        error={errors['pass']}
                                        fieldName="pass"
                                        register={register}
                                        className="field"
                                        placeholder={t('profile.data.passport')}
                                    />
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.citizenship')}
                                        value={citizenship}
                                        setValue={setCitizenship}
                                        error={errors['citizenship']}
                                        fieldName="citizenship"
                                        register={register}
                                        className="field"
                                        placeholder={t('profile.data.citizenshipPlaceholder')}
                                    />
                                </Col>
                                <Col sm="6">
                                  <Field
                                    label={t('profile.data.unsubscribed_news')}
                                    value={isUnsubscribedNews}
                                    setValue={setIsUnsubscribedNews}
                                    error={errors['unsubscribed_news']}
                                    fieldName="unsubscribed_news"
                                    register={register}
                                    className="field"
                                    type='checkbox'
                                    orientation='horizontal'
                                    inputStyles={{ position: 'static', margin: 0 }}
                                  />
                                </Col>
                                <Col sm="6">
                                  <div className="field">
                                    <Label className="mt-2 input-label">
                                      {t('profile.data.passportFiles')}:
                                    </Label>
                                    <PrivateFileComponent type={Constants.PRIVATE_FILE_TYPES.PASSPORT} />
                                  </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className="submitButton">
                    <FormButton
                      value={t('profile.data.save')}
                      type="submit"
                      className="small-button center"
                    />
                    {isSuccessfulUpdate && <p className="final-success">{t('profile.data.success')}</p>}
                </div>
            </form>
          {isModalOpen && <TwfaModal
            toggle={() => setIsModalOpen(!isModalOpen)}
            customHandler={(code) => handleUpdate({ ...presaveValues, code })}
            email={email}
          />}
        </Container>
    )
}

export default PersonalData