import {Link} from "react-router-dom";
import React from "react";
import {Col, Container, Row} from "reactstrap";
import MMarineLogo from "../../styles/assets/images/menu/WRLogoWhite.svg";
import "./style.scss";
import {t} from "i18next";
// @ts-ignore
import { EVENT_TYPES } from 'sv-common/constants/events';
// @ts-ignore
import getCountryValue from '../../lib/utils/getCountryValue';

const Tablet = () => <Container fluid className="tabletPage vh-100">
    <img src={MMarineLogo} alt="" className="tabletPage-logo" />
    <Row className="tabletPage-links">
      <Col className="p-0 overflow-hidden" sm={12} md={6}>
        <Link to={`/tablet/yachts/${getCountryValue({ en: `?events=${EVENT_TYPES.TRAINING},${EVENT_TYPES.SPECIAL}` })}`} className="h-100 d-flex justify-content-center align-items-center tabletPage-link
            tabletPage-link__yachts">
            {t('tablet.walk')}
        </Link>
      </Col>
      <Col className="p-0 overflow-hidden" sm={12} md={6}>
        <Link to={`/tablet/sport/${getCountryValue({ en: `?events=${EVENT_TYPES.RACE_FOR_BEGINNERS},${EVENT_TYPES.RACE},${EVENT_TYPES.MATCH_TRIP}` })}`} className="h-100 d-flex justify-content-center align-items-center tabletPage-link
            tabletPage-link__sport">
            {t('tablet.sport')}
        </Link>
      </Col>
    </Row>
</Container>

export default Tablet