// eslint-disable-next-line
import React, {useRef} from 'react';
import {NODE_ENV, RECAPTCHA_KEY} from "../../api/useApi";
import ReCAPTCHA from "react-google-recaptcha";

/**
 *
 * @param {function} [cb] - Function to call with received token;
 */
export const useCaptcha = (cb) => {
    const recaptchaRef = useRef(undefined);
    const cbWithCaptcha = async (insideCallback) => {
        if (NODE_ENV === 'development') {
            cb && cb('test');
            insideCallback && insideCallback('test')
            return;
        }
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset()
        cb && cb(token)
        insideCallback && insideCallback(token)
    }
    return [
        cbWithCaptcha,
        NODE_ENV === 'development'
            ? <></>
            :  <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RECAPTCHA_KEY}
            />
    ]
}