import { observer } from 'mobx-react-lite';
import PaymentTimer from '../../common/PaymentTimer';
import Divider from '../../generic/Divider';
import { Col, Input, Label, Row } from 'reactstrap';
import MemberCheckoutCard from './MemberCheckoutCard';
import EnterPromocode from '../../generic/EnterPromocode';
import { calculatePromoDiscount } from '../../../lib/utils/helpers';
import external from '../../../styles/assets/images/sv/external.svg';
import FormButton from '../../generic/buttons/main';
import HasPaidMessage from '../../common/HasPaidMessage';
import React from 'react';
import { useForm } from 'react-hook-form';
import i18next from 'i18next';
import isNumber from 'lodash/isNumber';

const MembersCheckoutUI = ({
                             arrival,
                             order,
                             elapsedTime,
                             setIsSubmitDisabled,
                             membersData,
                             promocodeInput,
                             promocodeApplied,
                             verifyPromocode,
                             promocodeChange,
                             invalidPromocodeReason,
                             certificateApplied,
                             promoAndCertDiscount,
                             currentData,
                             isSubmitDisabled,
                             handleUpdate,
                             handleChange,
                             setPromocodeApplied,
                             setCertificateApplied,
                           }) => {

  const {t, language} = i18next;

  const {register, errors, handleSubmit} = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  return (
    <>
      <div className={"checkout"} data-testid='checkout'>
        <div className={"checkout__main-info"}>
          <div className="title-label">{t('travel.arrivalDate')}</div>
          <div className="checkout__date">{
            (arrival?.start_date && arrival?.end_date)
              ? (<>
                {new Date(arrival?.start_date).toLocaleString(language, { month: "long", day: "numeric" }) || ''}{" "}
                - {new Date(arrival?.end_date).toLocaleString(language, { month: "long", day: "numeric" }) || ''}
              </>)
              : <div style={{height: '24px', marginBottom: '6px'}} />
          }
          </div>
          {!order?.hasPaid && isNumber(elapsedTime) &&
          <PaymentTimer
            elapsedTime={elapsedTime}
            className="pl-0 pt-1 pr-1"
            setIsSubmitDisabled={setIsSubmitDisabled}
          />
          }
          <Divider />
          <div className="checkout__description">{arrival?.name}</div>
          <Row className={"no-gutters"}>
            <Col xs={12} sm={6}>
              <div className="title-label">{t('travel.include')}</div>
              <ul className="items-list">
                {arrival?.options_included_ids.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Col>
            <Col xs={12} sm={6} className={"pl-2"}>
              <div className="title-label">{t('travel.notInclude')}</div>
              <ul className="items-list">
                <ul className="items-list">
                  {arrival?.options_not_included_ids.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </ul>
            </Col>
          </Row>
        </div>
        <Divider/>
        {membersData?.map((item, index) =>
          <MemberCheckoutCard chosenArrival={arrival} index={index} member={item} key={index}/>)}

        <EnterPromocode promocodeInput={promocodeInput} promocodeApplied={promocodeApplied}
                        verifyPromocode={verifyPromocode} promocodeChange={promocodeChange}
                        invalidPromocodeReason={invalidPromocodeReason}
                        certificateApplied={certificateApplied} setPromocodeApplied={setPromocodeApplied}
                        setCertificateApplied={setCertificateApplied} promoAndCertDiscount={promoAndCertDiscount}
                        promocodeDiscount={calculatePromoDiscount(
                          promocodeApplied, arrival?.price_value, arrival?.adventure_id?.price_value,
                          promocodeApplied?.discountValue
                        )}
        />
        <Divider/>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <div className="checkout__input-wrapper">
            <div className="title-label">{t('inputs.comment.label')}</div>
            <Input type="textarea" id="comment" name="comment" onChange={handleChange}
                   innerRef={register({required: false, maxLength: 300})} defaultValue={currentData.comment}
                   placeholder={t('inputs.comment.travelPlaceholder')}
            />
            <span className={errors.comment ? "error-label" : "error-label d-none"}>
              {errors.comment?.type === "required" ? t('inputs.required') : ""}
              {errors.comment?.type === "maxLength"
              && t('inputs.comment.maxLength')}
            </span>
          </div>
          <Divider/>
          <div className="checkout__input-wrapper">
            <Input type="checkbox" id="validDataConsent" defaultChecked={currentData.validDataConsent}
                   name="validDataConsent" onChange={handleChange} innerRef={register({required: true})}
            />
            <Label htmlFor="validDataConsent" className="offer-label">
              <a href={t("agreements.personalData")} target="_blank" rel="noopener noreferrer">
                {t('inputs.consents.validData')}
                <img src={external} alt=""/>
              </a>
            </Label>
            <span className={errors.validDataConsent ? "error-label" : "error-label d-none"}>
              {errors.validDataConsent?.type === "required"
                ? t('inputs.consents.validDataError')
                : ""}
            </span>
          </div>
          <div className="checkout__input-wrapper">
            <Input type="checkbox" id="publicOfferConsent"
                   defaultChecked={currentData.publicOfferConsent}
                   name="publicOfferConsent" onChange={handleChange} innerRef={register({required: true})}
            />
            <Label htmlFor="publicOfferConsent" className="offer-label">
              <a href={arrival?.public_contract_url} target="_blank" rel="noopener noreferrer">
                {t('inputs.consents.publicOffer')}
                <img src={external} alt=""/>
              </a>
            </Label>
            <span className={errors.publicOfferConsent ? "error-label" : "error-label d-none"}>
              {errors.publicOfferConsent?.type === "required"
                ? t('inputs.consents.publicOfferError')
                : ""}
            </span>
          </div>
          <FormButton value={promoAndCertDiscount?.price ? t('common.pay') : t('common.schedule')}
                      type="submit" disabled={isSubmitDisabled || order?.hasPaid}/>
          {order?.hasPaid && <HasPaidMessage />}
        </form>
      </div>
    </>
  );
}

export default observer(MembersCheckoutUI);