import { IPromocode } from '../../../lib/utils/types';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { IPromoService, PromoServiceStoreToken } from '../api/service';
import { makeAutoObservable } from 'mobx';

export interface IPromoModel extends IPromoService {
  item?: IPromocode,
}

export default class PromoModel implements IPromoModel {
  item?: IPromocode;

  constructor(
    private service: IPromoService,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setItem(v: IPromocode) {
    this.item = v;
  }

  check(productType: string, productId: number, name: string, orderId: number): Promise<{ status: string; extraInfo: string; reason: string }> {
    return this.service.check(productType, productId, name, orderId);
  }
}

export const PromoModelStoreToken = token<IPromoModel>('PromoModelStoreToken');

container.bind(PromoModelStoreToken).toInstance(PromoModel).inSingletonScope();

injected(PromoModel, PromoServiceStoreToken);