import React, {useEffect} from 'react';
import {Button, Row} from 'reactstrap';
import './style.scss';
import {useRedirect} from "../../../../lib/utils/hooks/useRedirect";
import Logo from "../../../layout/ Logo";
import PasswordLogin from "./PasswordLogin";
import PhoneLogin from "./PhoneLogin";
import {features} from "../../../../lib/api/globalState";
import {FORM_STEPS} from "../../../../lib/utils/constants";
import ElementWithAccessInCountries from "../../../../lib/utils/hocs/ElementWithAccessInCountries";
import i18next from "i18next";
import { useApi } from '../../../../lib/api/useApi';
import { useInjection } from 'brandi-react';
import { LoginModelStoreToken } from './model';


const Login = ({isMMarine, isRedirectAfterLogin, formStep}) => {
    const loginModel = useInjection(LoginModelStoreToken);

    const {generateSocialMediaLink} = useApi();

    const {redirectTo} = useRedirect();

    useEffect(() => {
      loginModel.mmarineSocialLogin();
    }, []);

    const { t } = i18next;

    const onLoginSuccess = (v) => loginModel.onLoginSuccess(v, isRedirectAfterLogin, redirectTo);

    return <div id={'login'} className="login">
        <div className={'login-container'}>
            <div className="login-logo">
                <Logo isMMarine={isMMarine}/>
            </div>
            {formStep === FORM_STEPS.PASSWORD_LOGIN || !features['SV-1817'] ? <PasswordLogin onLoginSuccess={onLoginSuccess} /> : <PhoneLogin onLoginSuccess={onLoginSuccess} />}
            <div className="login-text login-social-text">
                {t('common.logInVia')}
            </div>
            <Row className={'no-gutters justify-content-center'}>
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                  <Button tag={'a'} className="social-btn social-vk" href={generateSocialMediaLink('vk')}/>
                </ElementWithAccessInCountries>
                <ElementWithAccessInCountries hideInCountries={['RU']}>
                  <Button tag={'a'} className="social-btn social-google" href={generateSocialMediaLink('google')}/>
                </ElementWithAccessInCountries>
            </Row>
        </div>
    </div>
}

export default Login;
