import { PaymentPayMethodsObject, TPaymentPayMethods } from '../constants';
import PaymentMethodCashComponent from './cash';
import PaymentMethodCardComponent from './card';
import PaymentMethodAbonementComponent from './abonement';
import { FC } from 'react';
import PaymentMethodCertComponent from './cert';
import { ICertificate, IPromocode, TOrder, TPromoAndCertDiscount } from '../../../../lib/utils/types';

export interface IPaymentExtraBlockProps {
  verifyPromocode: (value: string, type?: string) => void,
  order: TOrder,
  setPromocodeApplied: (value?: IPromocode) => void,
  setCertificateApplied: (value?: ICertificate) => void,
  promoAndCertDiscount: TPromoAndCertDiscount,
  isShowPromocode: boolean,
}

const methodsObject: Record<TPaymentPayMethods, FC<IPaymentExtraBlockProps>> = {
  [PaymentPayMethodsObject.CASH]: PaymentMethodCashComponent,
  [PaymentPayMethodsObject.CARD]: PaymentMethodCardComponent,
  [PaymentPayMethodsObject.SUB]: PaymentMethodAbonementComponent,
  [PaymentPayMethodsObject.CERT]: PaymentMethodCertComponent,
}

const getPaymentExtraBlock = (method: TPaymentPayMethods): FC<IPaymentExtraBlockProps> => {
  return methodsObject[method];
}

export default getPaymentExtraBlock;