// eslint-disable-next-line
import React from 'react';
import {useApi} from "./useApi";

export const usePublicEndpoints = () => {
    const { request, rawRequest } = useApi();

    /**
     * Method to get slots for chosen base and boat class
     * @param {string} baseId - ID выбранной базы
     * @param {string} boatClass - Выбранный класс лодок
     * @param {string} chosenDate - Выбранный день, формат "YYYY-MM-DD"
     * @param {string} eventURL
     * @param {string} eventsList - список типов событий, которые будут доступны в выдаче

     * @return {Promise<*>}
     */
    const getSlots = async (baseId, boatClass, chosenDate, eventURL, eventsList, eventsUrls) =>
        rawRequest('get', '/public/slots/', {baseId, boatClass, chosenDate, eventURL, eventsList, eventsUrls});

    /**
     * Method to get available dates for chosen base and boat class
     * @param {string} baseId - ID выбранной базы
     * @param {string} boatClass - Выбранный класс лодок
     * @param {string} eventsList - Список доступных типов событий
     * @return {Promise<*>}
     */
    const getAvailableDates = async (baseId, boatClass, eventsList) =>
        rawRequest('get', `/public/bases/dates/?baseId=${baseId}&boatClass=${boatClass}&eventsList=${eventsList}`);

    /**
     * Method to get bases and boats for sport
     * @return {Promise<*>}
     */
    const getBases = async (query) => rawRequest('get', `/public/bases/`, query);

    /**
     * Method to get specific bases and boats for sport
     * @return {Promise<*>}
     */
     const getSpecificBases = async (urls, query) => rawRequest('get', `/public/bases/?urls=${urls}`, query);

    /**
     * Method to get places for requested boat class
     * @return {Promise<*>}
     */
    const getBoatsPlaces = async (className) => rawRequest('get', `/public/boats/places/${className}`);

    /**
     * Method to create payment with specified data
     * @param {string} id - id выбранного заказа
     * @param {string} url - URL вовзрата из Fondy
     * @param {object} stateToUse - стейт после авторизации, если globalState не успел обновиться
     * @return {Promise<*>}
     */
    const createPayment = async (id, url, stateToUse = null, paid_locally) =>
        request('post', '/public/payment/', {id, url, paid_locally}, stateToUse);

    /**
     * Method to get arrivals for specified adventure
     * @param {string} adventure_url - URL выбранного приключения, должен передаваться из Тильды
     * @return {Promise<*>}
     */
    const getArrivals = async (adventure_url) =>
        rawRequest('get', `/public/arrivals/${adventure_url}`);

    /**
     * Method to get slots for specified event
     * @param {string} event_url - URL выбранного события, должен передаваться из Тильды
     * @return {Promise<*>}
     */
    const getEventByUrl = async (event_url) => rawRequest('get', `/public/bases/event/${event_url}`);

    /**
     * Method to get slot for specified slot name
     * @param {string} slot_url - URL выбранного слота, должен передаваться из Тильды
     * @return {Promise<*>}
     */
    const getSlotByUrl = async (slot_url) => rawRequest('get', `/public/slots/${slot_url}`);

    /**
     * Method to get shirt sizes
     * @return {Promise<*>}
     */
    const getShirtSizes = async () => rawRequest('get', '/public/sizes');

    /**
     * Method to get currencies
     * @return {Promise<*>}
     */
    const getCurrencies = async () => rawRequest('get', '/public/currencies');

    /**
     * Method to get difficulty levels
     * @return {Promise<*>}
     */
    const getDifficultyLevels = async () => rawRequest('get', '/public/difficulty-levels');

    /**
     * Method to create an order
     * @param {object} data
     * @param {object} stateToUse - стейт после авторизации, если globalState не успел обновиться
     * @return {Promise<*>}
     */
    const createOrder = async (data, stateToUse = null) => request('post', `/public/order`, data, stateToUse);

    /**
     * Method to update an order
     * @param {number} id
     * @param {object} data
     * @return {Promise<*>}
     */
    const updateOrder = async (id, data) => request('put', `/public/order/${id}`, data);

    /**
     * Method to get an order
     * @param {number | string} id
     * @return {Promise<*>}
     */
    const getOrder = async (id) => request('get', `/public/order/${id}`);

    /**
     * Method to check a promocode
     * @param {string} productType
     * @param {string || number} productId
     * @param {string} name
     * @param {string} orderId
     * @return {Promise<unknown>}
     */
    const checkPromocode = async (productType, productId, name, orderId) => request('get', `/public/promocode/valid?productType=${productType}&productId=${productId}&name=${name}&orderId=${orderId}`);

    /**
     * Method to get available places in specific product
     * @param {string} productType 
     * @param {number} productId 
     * @return {Promise<*>}
     */
    const getProductPlaces = async (productType, productId) => rawRequest('get', `/public/${productType}/${productId}/places`);

    /**
     * Method to get logged in user's fields (without params in request)
     * Getting fields:
     * Personal: first_name, last_name, phone, email, sailing_experience,
     * Subscription: deposit_sum, subscription_type_id, keel_all_luch_group_discount_size, luch_individual_discount_size
     * @return {Promise<*>}
     */
    const getUserCleanFields = async (isRedirectWithoutLastName = true, stateToUse = null) => {
        return request('get', `/public/user/`, null, stateToUse)
    }

    /**
     * Method to get remaining seconds of availability to pay an order
     * @param {*} id 
     * @returns 
     */
    
    const getOrderTimeout = async (id) => request('get', `/public/order/${id}/time/?timestamp=${Date.now()}`)

    /**
     * Get subscriptions that can be applied to the order
     * @returns {Promise<*>}
     * @param {int|undefined} orderId
     */
    const getSubscriptions = async (orderId) => rawRequest('get', `/public/subscriptions/`, {orderId})
    const calculateSubscription = async (orderId) => request('get', `/public/subscriptions/calculate/`, {orderId})

    const updateClient = async (userId, data) => request('put', `/public/user/${userId}`, data)

    /**
     * Method to get arrival for arrival url
     * @param {string} arrival_url - URL выбранного заезда, должен передаваться из Тильды
     * @return {Promise<*>}
     */
    const getArrivalByUrl = async (arrival_url) => rawRequest('get', `/public/arrival/${arrival_url}`);

    const getAddressSuggestions = async (data) => request('post', '/public/address/suggestions', data);

    /**
     * Method to check a certificate
     * @param {string} certificateName
     * @param {string} captcha
     * @return {Promise<unknown>}
     */
    const getCertificateInfo = async (certificateName, captcha) =>
        request('get', `/public/certificate/${certificateName}`, {captcha})


    /**
     * Method to check a certificate
     * @param {string} certificateName
     * @param {string} captcha
     * @param {string} productType
     * @param {number} productId
     * @return {Promise<unknown>}
     */
    const checkCertificate = async (certificateName, captcha, productType, productId) =>
        request('get', `/public/certificate/${certificateName}/check`, {captcha, product: productType, productId})

    const downloadCertificate = async (certificateName) =>
        request('get', `/public/certificates/${certificateName}/download/`)

    const getEventsByUrls = async (events) => {
        return request('get', `/public/bases/events-by-urls?eventsList=${events}`)
    }

    const getEventsByCorp = async (slug) => {
        return request('get', `/public/bases/events-by-corp?slug=${slug}`)
    }

    const getOrdersStatistic = async () => {
      return request('get', '/public/orders/statistic');
    }

    return {
        getArrivals, getShirtSizes, createOrder, checkPromocode, updateOrder, getOrder, getCurrencies,
        getDifficultyLevels, createPayment, getSlots, getAvailableDates, getBases, getSpecificBases, getBoatsPlaces,
        getEventByUrl, getSlotByUrl, getProductPlaces, getUserCleanFields, getOrderTimeout, getSubscriptions,
        calculateSubscription, updateClient, getArrivalByUrl, getAddressSuggestions, checkCertificate,
        downloadCertificate, getCertificateInfo, getEventsByUrls, getEventsByCorp, getOrdersStatistic
    }
}