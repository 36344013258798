import React, {Fragment} from "react";
import {Col, Input, Label, Row} from "reactstrap";
import "./style.scss";
import valid from "../../../styles/assets/images/sv/valid.svg";
import invalid from "../../../styles/assets/images/sv/invalid.svg";
import PropTypes from "prop-types";
import {localizeDate, regexes, trimOnChange} from '../../../lib/utils/helpers';
import DatePickerInput from "../../generic/DatePickerInput";
import { isDateExpired, datetimeToISODate } from "../../../lib/utils/format";
import {LANGUAGE_VALIDATE_FIELDS} from "../../../lib/utils/constants";
import PhoneField from "../../common/PhoneField";
import AddressFieldWithAutocomplete from "../../common/AddressFieldWithAutocomplete";
import { usePublicEndpoints } from '../../../lib/api/usePublicEndpoints';
import {Link} from "react-router-dom";
import {
    currentDate,
    maxForeignPassportDate,
    minBirthDate,
    minForeignPassportDate,
    minPassportDate
} from "../../../lib/utils/calendar";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import PrivateFileComponent from '../../common/PrivateFile';
import { Constants } from 'sv-common';
import { DropZoneTypes } from '../../generic/Dropzone';
import { TG_OPTIONS } from 'sv-common/constants/statuses';
import { useInjection } from 'brandi-react';
import { AdventureContainerModelStoreToken } from '../AdventureContainer/model';
import { observer } from 'mobx-react-lite';

const SPECIAL_FIELDS = ['shirt_size', 'passport_date_foreign', 'passport_date_ru']

const MemberCard = ({arrival, index, form, shirtSizes, updatePeopleData}) => {
    const model = useInjection(AdventureContainerModelStoreToken);

    const inputMembersData = model.peopleData;
    const defaultValues = index === 0 ? model.defaultValues : {};

    const emailChange = (e) => {
        updatePeopleData(e)
        trimOnChange(e)
    }

    const {
        birthdate, city, email, first_name, gender, last_name, phone, sailing_experience,
        ...additionalFields
    } = arrival.adventure_id?.req_fields;

    const {register, errors, clearErrors, getValues, control, setError} = form;
    let values = getValues();

    const handlePassportNumber = (e, index) => {
        const {name, value} = e.target;
        if (additionalFields.passport_date_foreign && name.startsWith('passport_num_foreign') && value === '0000') {
            clearErrors(`passport_date_foreign-${index}`);
        } else if (additionalFields.passport_date_foreign && isDateExpired(model.peopleData[index].passport_date_foreign, arrival.end_date)) {
            setError(`passport_date_foreign-${index}`, {
                type: "validate",
                message: t('inputs.passportDate.error')
            });
        }
        updatePeopleData(e)
    }

    const isDateFormat = (str) => /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/.test(str);

    const additionalData = (() => {
        const firstNameDefaultValue = defaultValues?.first_name || inputMembersData[index]?.first_name;
        const lastNameDefaultValue = defaultValues?.last_name;
        const emailDefaultValue = defaultValues?.email || inputMembersData[index]?.email;

        return {
            firstName: {
                defaultValue: firstNameDefaultValue,
                pattern: !index ? false : regexes.personalDataRegex,
                disabled: !index && defaultValues?.first_name,
            },
            lastName: {
                defaultValue: lastNameDefaultValue,
                pattern: !index && lastNameDefaultValue ? false : regexes.personalDataRegex,
                disabled: !index && lastNameDefaultValue,
            },
            email: {
                defaultValue: emailDefaultValue,
                pattern: !index ? false : regexes.emailRegex,
                disabled: !index,
            }
        }
    })()
    const {getAddressSuggestions} = usePublicEndpoints();
    const birthdateDefaultValue = defaultValues?.birthdate || inputMembersData[index]?.birthdate;
    const isFirst = index === 0;
    const tgOptions = arrival?.tg_options || arrival?.adventure_id?.tg_options;

    return (
        <div className={"member"}>
            <div className={"member__title"}>{t('common.member')} #{index + 1}</div>
            <Row>
                <Col xs={12} md={6} className={"control-wrapper"}>
                    <span className={"input-label"}>{t('travel.firstName.label')}</span>
                    {/* Здесь и дальше к name и id полей добавляется index конкретного участника для того, чтобы React Hook Form смог корректно валидировть каждое поле в отдельности. Если index убрать, одинаковые поля у участников будут валидироваться как одно */}
                    {(errors[`first_name-${index}`] || values[`first_name-${index}`]) && (
                        <span>
                                <img src={errors[`first_name-${index}`] ? invalid : valid} alt=""/>
                            </span>
                    )}
                    <Input
                        type="text"
                        id={`first_name-${index}`}
                        name={`first_name-${index}`}
                        placeholder={t('travel.firstName.placeholder')}
                        defaultValue={additionalData.firstName.defaultValue}
                        onChange={updatePeopleData}
                        innerRef={register({
                            pattern: additionalData.firstName.pattern,
                            required: true,
                        })}
                        disabled={additionalData.firstName.disabled}
                    />
                    <span className={errors[`first_name-${index}`] ? "error-label" : "error-label d-none"}>
                        {errors[`first_name-${index}`]?.type === "required"
                            ? t('inputs.required')
                            : ""}
                        {errors[`first_name-${index}`]?.type === "pattern"
                            ? t('inputs.pattern')
                            : ""}
                    </span>
                </Col>
                <Col xs={12} md={6} className={"control-wrapper"}>
                    <span className={"input-label"}>{t('travel.lastName.label')}</span>
                    {(errors[`last_name-${index}`] || values[`last_name-${index}`]) && (
                        <span>
                            <img src={errors[`last_name-${index}`] ? invalid : valid} alt=""/>
                        </span>
                    )}
                    <Input
                        type="text"
                        id={`last_name-${index}`}
                        name={`last_name-${index}`}
                        placeholder={t('travel.lastName.placeholder')}
                        defaultValue={additionalData.lastName.defaultValue}
                        onBlur={updatePeopleData}
                        innerRef={register({
                            pattern: additionalData.lastName.pattern,
                            required: true,
                        })}
                        disabled={additionalData.lastName.disabled}
                    />
                    <span className={errors[`last_name-${index}`] ? "error-label" : "error-label d-none"}>
                        {errors[`last_name-${index}`]?.type === "required" ? t('inputs.required') : ""}
                        {errors[`last_name-${index}`]?.type === "pattern"
                            ? t('inputs.pattern')
                            : ""}
                    </span>
                </Col>

                <Col xs={12} md={6} className={"control-wrapper"}>
                    <div className={"input-label radio"}>{t('inputs.gender.label')}</div>
                    <Input
                        id={`gender-m-${index}`}
                        name={`gender-${index}`}
                        type="radio"
                        value="m"
                        defaultChecked={defaultValues?.gender === "m" || inputMembersData[index]?.gender === "m"}
                        onChange={updatePeopleData}
                        innerRef={register({
                            required: true
                        })}
                    />
                    <Label htmlFor={`gender-m-${index}`}>{t('inputs.gender.male')}</Label>
                    <Input
                        id={`gender-f-${index}`}
                        name={`gender-${index}`}
                        type="radio"
                        value="f"
                        defaultChecked={defaultValues?.gender === "f" || inputMembersData[index]?.gender === "f"}
                        onChange={updatePeopleData}
                        innerRef={register({
                            required: true
                        })}
                    />
                    <Label htmlFor={`gender-f-${index}`}>{t('inputs.gender.female')}</Label>
                    <ElementWithAccessInCountries hideInCountries={['RU']}>
                      <Input
                        id={`gender-n-${index}`}
                        name={`gender-${index}`}
                        type="radio"
                        value="-"
                        defaultChecked={defaultValues?.gender === "-" || inputMembersData[index]?.gender === "-"}
                        onChange={updatePeopleData}
                        innerRef={register({
                          required: true
                        })}
                      />
                      <Label htmlFor={`gender-n-${index}`}>-</Label>
                    </ElementWithAccessInCountries>
                    <div className={errors[`gender-${index}`] ? "error-label w-100" : "error-label d-none"}>
                        {errors[`gender-${index}`]?.type === "required" ? t('inputs.gender.error') : ""}
                    </div>
                </Col>
                <Col xs={12} md={6} className={"control-wrapper"}>
                    <span className={"input-label"}>{t('inputs.birthdate.label')}</span>
                    {(errors[`birthdate-${index}`] || values[`birthdate-${index}`]) && (
                        <span>
                            <img src={errors[`birthdate-${index}`] ? invalid : valid} alt=""/>
                        </span>
                    )}
                    <DatePickerInput
                        defaultValue={birthdateDefaultValue ? localizeDate(birthdateDefaultValue) : ''}
                        error={errors[`birthdate-${index}`]}
                        name={`birthdate-${index}`}
                        blurFunc={updatePeopleData}
                        id={`birthdate-${index}`}
                        data-testid={`birthdate-${index}`}
                        placeholder="01.01.1999"
                        control={control}
                        rules={{
                            required: true,
                            pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,

                        }}
                        minDate={minBirthDate}
                        maxDate={currentDate}
                    />
                </Col>

                <Col xs={12} md={6} className={"control-wrapper"}>
                    <span className={"input-label"}>{t('inputs.phone.label')}</span>
                    <PhoneField
                        index={index}
                        control={control}
                        register={register}
                        error={errors[`phone-${index}`]}
                        setPhoneValue={updatePeopleData}
                        phoneValue={model.peopleData[index]?.phone || inputMembersData[index]?.phone}
                    />
                    <span className={errors[`phone-${index}`] ? "error-label" : "error-label d-none"}>
                        {errors[`phone-${index}`]?.type === "required" ?  t('inputs.required') : ""}
                        {errors[`phone-${index}`]?.type === "pattern"
                            ? t('inputs.phone.pattern')
                            : ""}
                        {errors[`phone-${index}`]?.type === "phoneTaken" ? errors[`phone-${index}`]?.message : ''}
                    </span>
                </Col>
                <Col xs={12} md={6} className={"control-wrapper"}>
                    <span className={"input-label"}>{t('inputs.sailingExperience.label')}</span>
                    {(errors[`sailing_experience-${index}`] || values[`sailing_experience-${index}`]) && (
                        <span>
                            <img src={errors[`sailing_experience-${index}`] ? invalid : valid} alt=""/>
                        </span>
                    )}
                    <select
                        id={`sailing_experience-${index}`}
                        defaultValue={defaultValues?.sailing_experience || inputMembersData[index]?.sailing_experience || ""}
                        onChange={updatePeopleData}
                        name={`sailing_experience-${index}`}
                        ref={register({required: true})}
                    >
                        <option value="" disabled hidden>
                            {t('inputs.sailingExperience.defaultValue')}
                        </option>
                        <option value="0/7">{t('inputs.sailingExperience.travel0/7')}</option>
                        <option value="1/7">{t('inputs.sailingExperience.travel1/7')}</option>
                        <option value="2/7">{t('inputs.sailingExperience.travel2/7')}</option>
                        <ElementWithAccessInCountries hideInCountries={['EN']}>
                          <option value="3/7">{t('inputs.sailingExperience.travel3/7')}</option>
                          <option value="4/7">{t('inputs.sailingExperience.travel4/7')}</option>
                        </ElementWithAccessInCountries>
                    </select>
                    <span className={errors[`sailing_experience-${index}`] ? "error-label" : "error-label d-none"}>
                            {errors[`sailing_experience-${index}`]?.type === "required" ? t('profile.data.experienceDefaultLabel') : ""}
                        </span>
                </Col>

                <AddressFieldWithAutocomplete 
                    label={t('inputs.city.label')} id={`city-${index}`} name={`city-${index}`}
                    error={errors[`city-${index}`]} placeholder={t('inputs.city.placeholder')}
                    fieldName={'city'} defaultValue={defaultValues?.city || inputMembersData[index]?.city} 
                    required register={register} onChange={value => updatePeopleData({target: {name: `city-${index}`, value}})} 
                    language={arrival.adventure_id?.type === 'russia' ? 'ru' : 'en'} getAddressSuggestions={getAddressSuggestions} 
                />

                <AddressFieldWithAutocomplete 
                    label={t('inputs.region.label')} id={`region-${index}`} name={`region-${index}`}
                    error={errors[`region-${index}`]} placeholder={t('inputs.region.placeholder')}
                    fieldName={'region'} defaultValue={defaultValues?.region || inputMembersData[index]?.region} 
                    required register={register} onChange={value => updatePeopleData({target: {name: `region-${index}`, value}})} 
                    language={arrival.adventure_id?.type === 'russia' ? 'ru' : 'en'} getAddressSuggestions={getAddressSuggestions} 
                />

                {tgOptions !== TG_OPTIONS.HIDDEN && <Col xs={12} md={6} className={"control-wrapper"}>
                          <span className={"input-label"}>{t('common.telegram')}</span>
                        {(errors[`telegram-${index}`] || values[`telegram-${index}`]) && (
                          <span>
                          <img src={errors[`telegram-${index}`] ? invalid : valid} alt=""/>
                          </span>
                          )}
                          <Input
                          type="text"
                          id={`telegram-${index}`}
                          name={`telegram-${index}`}
                          placeholder="@silavetrasila"
                          defaultValue={defaultValues?.telegram || inputMembersData[index]?.telegram}
                          onChange={updatePeopleData}
                          innerRef={register({
                          required: tgOptions === TG_OPTIONS.REQUIRED,
                          pattern: /^([^А-ЯЁа-яё])+$/
                        })}
                          />
                          <span className={errors[`telegram-${index}`] ? "error-label" : "error-label d-none"}>
                        {errors[`telegram-${index}`]?.type === "required" ?  t('inputs.required') : ""}
                        {errors[`telegram-${index}`]?.type === "pattern" ? t('inputs.telegram.pattern') : ""}
                    </span>
                </Col>}
                <Col xs={12} md={6} className={"control-wrapper"}>
                    <span className={"input-label"}>{t('inputs.email.label')}</span>
                    {(errors[`email-${index}`] || values[`email-${index}`]) && (
                        <span>
                            <img src={errors[`email-${index}`] ? invalid : valid} alt=""/>
                        </span>
                    )}
                    <Input
                        type="text"
                        id={`email-${index}`}
                        name={`email-${index}`}
                        placeholder="username@email.com"
                        defaultValue={additionalData.email.defaultValue}
                        onChange={emailChange}
                        innerRef={register({
                            required: true,
                            pattern: additionalData.email.pattern,
                        })}
                        disabled={additionalData.email.disabled}
                    />
                    <span className={errors[`email-${index}`] ? "error-label" : "error-label d-none"}>
                        {errors[`email-${index}`]?.type === "required" ?  t('inputs.required') : ""}
                        {errors[`email-${index}`]?.type === "pattern" ? t('inputs.email.pattern') : ""}
                    </span>
                </Col>
                {Object.keys(additionalFields).map((fieldKey, id) => {
                    const field = additionalFields[fieldKey]
                    const patternErrorText = arrival.adventure_id?.type === 'russia'
                        ? fieldKey.endsWith('_en') ? t('inputs.onlyLatinError') : t('inputs.onlyCyrillicError')
                        : t('inputs.onlyLatinError')

                    return (
                        !SPECIAL_FIELDS.includes(fieldKey) &&
                            <Col xs={12} md={6} className={"control-wrapper"} key={fieldKey + '-' + id}>
                                    <span className={"input-label"}>
                                        {field.label}
                                    </span>
                                {(errors[`${fieldKey}-${index}`] || values[`${fieldKey}-${index}`]) && (
                                    <span>
                                        <img src={errors[`${fieldKey}-${index}`] ? invalid : valid} alt=""/>
                                    </span>
                                )}
                                <Input
                                    type="text"
                                    id={`${fieldKey}-${index}`}
                                    name={`${fieldKey}-${index}`}
                                    placeholder={field.label}
                                    defaultValue={defaultValues?.[fieldKey] || inputMembersData[index]?.[fieldKey]}
                                    onChange={e => handlePassportNumber(e, index)}
                                    innerRef={register({
                                        required: field.required,
                                        maxLength: fieldKey === 'passport_num_ru' ? 10 : false,
                                        pattern: LANGUAGE_VALIDATE_FIELDS[fieldKey] || false
                                    })}
                                />
                                <span className={errors[`${fieldKey}-${index}`] ? "error-label" : "error-label d-none"}>
                                    {errors[`${fieldKey}-${index}`]?.type === "required" ? t('inputs.required') : ""}
                                    {errors[`${fieldKey}-${index}`]?.type === "maxLength" ? t('inputs.maxLengthError') : ""}
                                    {errors[`${fieldKey}-${index}`]?.type === "pattern" && patternErrorText}
                                </span>
                            </Col>
                    )
                })}

                {additionalFields?.passport_date_foreign &&
                    <Col xs={12} md={6} className={"control-wrapper"}>
                        <span className={"input-label"}>
                            {additionalFields?.passport_date_foreign.label}
                        </span>
                        {(errors[`passport_date_foreign-${index}`] || values[`passport_date_foreign-${index}`]) && (
                            <span>
                                <img src={errors[`passport_date_foreign-${index}`] ? invalid : valid} alt=""/>
                            </span>
                        )}
                        <DatePickerInput
                            defaultValue={defaultValues?.passport_date_foreign
                                ? localizeDate(defaultValues?.passport_date_foreign)
                                : localizeDate(inputMembersData[index]?.passport_date_foreign)
                            }
                            error={errors[`passport_date_foreign-${index}`]}
                            name={`passport_date_foreign-${index}`}
                            id={`passport_date_foreign-${index}`}
                            blurFunc={updatePeopleData}
                            placeholder="01.01.2023"
                            control={control}
                            rules={{
                                required: additionalFields?.passport_date_foreign.required,
                                pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                                validate: value => {
                                    const passportNumber = model.peopleData[index]?.passport_num_foreign || inputMembersData[index]?.passport_num_foreign || defaultValues?.passport_num_foreign ;
                                    let passportDate = null;
                                    const preparedValue = isDateFormat(value) ? value : new Date(value).toISOString()
                                    if (index === 0) {
                                        passportDate = preparedValue || defaultValues?.passport_date_foreign
                                    } else {
                                        passportDate = preparedValue || inputMembersData[index]?.passport_num_foreign
                                    }
                                    return isDateExpired(
                                        datetimeToISODate(passportDate), arrival.end_date) && passportNumber !== '0000'
                                        ? t('inputs.passportDateForeign.error')
                                        : undefined
                                }
                            }}
                            minDate={minForeignPassportDate}
                            maxDate={maxForeignPassportDate}
                        />
                        <span className={errors[`passport_date_foreign-${index}`] ? "error-label" : "error-label d-none"}>
                            {errors[`passport_date_foreign-${index}`]?.type === "validate" ? errors[`passport_date_foreign-${index}`]?.message : ""}
                        </span>
                    </Col>
                }
                {additionalFields?.passport_date_ru &&
                    <Col xs={12} md={6} className={"control-wrapper"}>
                        <span className={"input-label"}>
                            {additionalFields?.passport_date_ru.label}
                        </span>
                        {(errors[`passport_date_ru-${index}`] || values[`passport_date_ru-${index}`]) && (
                            <span>
                                <img src={errors[`passport_date_ru-${index}`] ? invalid : valid} alt=""/>
                            </span>
                        )}
                        <DatePickerInput
                            defaultValue={defaultValues?.passport_date_ru
                                ? localizeDate(defaultValues?.passport_date_ru)
                                : localizeDate(inputMembersData[index]?.passport_date_ru)
                            }
                            error={errors[`passport_date_ru-${index}`]}
                            name={`passport_date_ru-${index}`}
                            id={`passport_date_ru-${index}`}
                            blurFunc={updatePeopleData}
                            placeholder="01.01.2023"
                            control={control}
                            rules={{
                                required: additionalFields?.passport_date_ru.required,
                                pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,

                            }}
                            minDate={minPassportDate}
                            maxDate={currentDate}
                        />
                    </Col>
                }
                {arrival && arrival?.adventure_id?.req_fields?.shirt_size?.required && (
                    <Col xs={12} className={"control-wrapper"}>
                        <div className={"input-label radio"}>
                            {arrival && arrival?.adventure_id?.req_fields?.shirt_size.label}
                        </div>
                        {shirtSizes.map((item, key) => (
                            <Fragment key={item + '-' + key}>
                                <Input
                                    key={`${key}-${index}-1`}
                                    id={`size-${key}-${index}-1`}
                                    name={`shirt_size-${index}`}
                                    type="radio"
                                    value={item.tag}
                                    defaultChecked={defaultValues.shirt_size === item.tag}
                                    innerRef={register({
                                        required: true
                                    })}
                                    onChange={updatePeopleData}
                                />
                                <Label key={`${key}-${index}-2`} htmlFor={`size-${key}-${index}-1`}>
                                    {item.tag}
                                </Label>
                            </Fragment>
                        ))}
                        <div className={errors[`shirt_size-${index}`] ? "error-label w-100" : "error-label d-none"}>
                            {errors[`shirt_size-${index}`]?.type === "required" ? t("inputs.shirtSize.required") : ""}
                        </div>
                    </Col>
                )}
                {isFirst && <ElementWithAccessInCountries hideInCountries={['RU']}>
                  <Col xs={12} md={6} className={'control-wrapper'}>
                    <span className={'input-label'}>{t('inputs.passportFile')}</span>
                    <PrivateFileComponent withSlider={false} type={Constants.PRIVATE_FILE_TYPES.PASSPORT} dropZoneType={DropZoneTypes.common} />
                  </Col>
                </ElementWithAccessInCountries>}
            </Row>
            {isFirst &&
                <small>
                    {t('common.canEditData')}&nbsp;<Link to="/me" target="_blank">{t('common.personalAccount')}</Link>
                </small>
            }
        </div>
    );
};

export default observer(MemberCard);

