import { prepareParticipantsList } from '../../../../../../lib/utils/helpers';
import { isFieldEmpty } from '../../../../../../lib/utils/helpers/formsHelpers';
import { t } from 'i18next';
import { parseErrors } from '../../../../../../lib/utils/errors';
import { usePublicEndpoints } from '../../../../../../lib/api/usePublicEndpoints';

const useOrder = (showPeopleForms, peopleData, query, selectedGroup, setIsLoading, setPeopleData, setError, setQuery, setServerError) => {
  const {createOrder} = usePublicEndpoints();

  const submit = () => {
    const modifiedPeopleData = showPeopleForms ? peopleData : [peopleData[0]];
    let data = {
      participants: prepareParticipantsList(modifiedPeopleData, query.people),
      product: "groups",
      productId: selectedGroup.id,
      payment_currency: selectedGroup.currency,
    };
    if (query.orderId) data.step = query.step;
    if(!isFieldEmpty('phone', data.participants, setError)) {
      setIsLoading(true);
      createOrder(data)
        .then(res => {
          setPeopleData(modifiedPeopleData)
          setQuery({step: 3, orderId: res.orderId})
        })
        .catch(e => {
          setServerError(t(parseErrors(e.response?.data?.message)))
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return { submit };
}

export default useOrder;