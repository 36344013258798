import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import {useApi} from "../../../lib/api/useApi";
import {useRedirect} from "../../../lib/utils/hooks/useRedirect";
import {eraseCookie, getCookie} from "../../../lib/utils/cookies";


const LoginRedirect = ({isMMarine}) => {
    const {authThroughSocialMedia} = useApi()
    const location = useLocation();
    const params = useParams();
    const history = useHistory();
    const {redirectTo, setRedirect, pushWithRedirect} = useRedirect();

    const mmarineSocialLogin = () => {
        if (getCookie("sv_booking_social_login") !== "true" && !isMMarine) {
            setRedirect(
                `${process.env.REACT_APP_BOOKING_URL_MMARINE}/m/connect/${params.providerName}/redirect${location.search}`
            );
            redirectTo();
            return true
        }
        eraseCookie("sv_booking_social_login");
        return false
    }

    useEffect(() => {
        if (mmarineSocialLogin()) return;

        authThroughSocialMedia(params.providerName, location)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
                }
                setRedirect(res.data.redirect);
                redirectTo();
                return res;
            })
            .catch((err, res) => {
                if (err?.response?.data?.message[0]?.messages[0]?.id === 'Auth.form.error.email.taken') {
                    pushWithRedirect((isMMarine ? '/m' : '') + '/login', {error: err.response.data.message[0].messages[0].id});
                }
            });
    }, [history, location.search, params.providerName]);

    return <p>
    </p>
};

LoginRedirect.propTypes = {
    isMMarine: PropTypes.bool
}

export default LoginRedirect;
