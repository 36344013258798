export const parseLoginResponse = (responseData, state, setState) => {
    const newState = {
        ...state,
        userAccessToken: responseData.jwt,
        userRefreshToken: responseData.refresh,
        userEmail: responseData.user.email,
        userName: responseData.user.username,
        userId: responseData.user.id,
        userRole: responseData.user.role.name,
        isLoginPopupOpen: false,
        clientRole: responseData.user.client?.role_name
    };
    setState(newState);
    return newState;
}