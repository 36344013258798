export const ru = {
    common: {
        success: "Успех!",
        next: 'Далее',
        discount: 'Скидка',
        pay: 'Оплатить',
        buy: 'Купить',
        price: 'Цена',
        schedule: 'Записаться',
        orderPaid: 'Этот заказ уже успешно оплачен',
        certificate: 'Сертификат',
        promocode: 'Промокод',
        bookingClosed: 'Запись закрыта',
        chooseOtherSlot: 'Выбрать другой слот',
        placesEnded: 'К сожалению, места закончились, но вы можете выбрать любое из доступных направлений',
        placesWithDiscountEnded1: 'Вы хотите купить больше мест ({{places}}) со скидкой, чем осталось ({{remainder}}). Чтобы всё получилось, уменьшите количество мест. Но если иначе никак, напишите в TG',
        placesWithDiscountEnded2: '— что-нибудь придумаем',
        toMain: 'На главную',
        pluralizePlaces: 'мест',
        freeSits: 'свободных мест',
        member: 'Участник',
        canEditData: 'Отредактировать данные можно в ',
        personalAccount: 'личном кабинете',
        noProductsAvailable: 'Кажется нет доступных продуктов...',
        goToCalendar: 'Перейти в календарь',
        orderDeletedDescription: 'Время пролетело быстро — места на это приключение совсем недавно закончились. Но у нас точно есть что-то в эти же даты или в похожей акватории.',
        orderDeletedDescription2: 'Команда участников этого приключения уже сформирована — мы продали все места. Мы будем рады увидеть вас на других маршрутах — у нас точно есть что-то в эти даты или в похожей акватории.',
        layout: {
            pickDate: 'Выбор дат',
            members: 'Участники',
            confirmation: 'Подтверждение',
            payment: 'Оплата',
            finish: 'Завершение',
            payExtraCardDesc: 'После нажатия система переведет вас на сайт оплаты',
        },
        fields: {
            "phone": {
                "label": "Номер телефона",
                "validation": {
                    "required": "Поле обязательно для заполнения",
                    "format": "Введите корректный номер телефона"
                }
            },
            "phoneCode": {
                "label": "Введите код",
                "validation": {
                    "required": "Поле обязательно для заполнения",
                    "invalidCode": "Некорректный код",
                    "unknownError": "Что-то пошло не так",
                    "phoneTaken": "Этот номер уже занят"
                }
            },
            "password": {
                "label": "Пароль",
                "signUpLabel": "Придумайте пароль",
                "placeholder": "С цифрами, а лучше и с символами",
                "strength": {
                    "easy": "Не вполне безопасный пароль",
                    "medium": "Пароль средней сложности",
                    "hard": "Выглядит безопасно"
                },
                "validation": {
                    "passError": "Ой! У нас нет такого сочетания почты и пароля",
                    "required": "Поле обязательно для заполнения",
                    "minLength": "Минимальная длина пароля - 5 символов",
                    "pattern": "Недопустимые знаки (возможны цифры, латинские буквы и спецсимволы ! @ # %)"
                }
            },
            "captcha": {
                "validation": {
                    "wrongCaptcha": "Ой! Вы не прошли капчу!"
                }
            },
            "email": {
                "label": "Email",
                "placeholder": "Для подтверждений и чеков",
                "validation": {
                    "emailTaken": "Такой email у нас уже есть",
                    "regEmailTaken": "Такой аккаунт у нас уже есть",
                    "required": "Поле обязательно для заполнения",
                    "pattern": "Кажется, в адресе почты ошибка"
                }
            },
            "consent": {
                "label": "Согласен на",
                "linkCaption": "обработку персональных данных",
                "validation": {
                    "consent": "Пожалуйста, подтвердите согласие на обработку персональных данных"
                }
            },
            "firstName": {
                "label": "Имя",
                "placeholder": "Как вас зовут?",
                "validation": {
                    "required": "Поле обязательно для заполнения",
                    "pattern": "Кажется, в имени ошибка"
                }
            },
            "lastName": {
                "label": "Фамилия",
                "validation": {
                    "required": "Поле обязательно для заполнения"
                }
            }
        },
        buttons: {
            "switch": "English",
            "getCode": "Получить код",
            "signUp": "Зарегистрироваться",
            "loginViaEmail": "Войти по почте",
            "loginViaPhone": "Войти по номеру",
            "send": "Отправить",
            "getNewCode": "Получить новый код",
            "logIn": "Войти",
            "recoverPassword": "Восстановить пароль"
        },
        logIn: {
            "phone": {
                "title": "Войти и зарегистрироваться",
                "codeSent": "Код отправлен на номер",
                "requestCodeText": "Запросить код повторно через"
            },
            "password": {
                "title": "Войдите, чтобы продолжить"
            }
        },
        logInVia: "Войти с помощью",
        helpers: {
            "or": "или",
            "change": "изменить"
        },
        forgotPassword: {
            "linkCaption": "Забыли пароль?",
            "title": "Введите почту, которая была привязана к аккаунту. Мы отправим вам ссылку для восстановления пароля",
            "backToLogIn": "Вернуться к авторизации",
            "accountNotExists": "Данного аккаунта не существует",
            "signUpSuggestion": "Зарегистрироваться?",
            "restoreSuccess": "Ссылка на восстановление пароля выслана на почту. Проверьте в спаме, если письма нет больше 5 минут"
        },
        signUp: {
            "title": "Создайте аккаунт",
            "gotAnAccount": "Уже есть аккаунт?",
            "logIn": "Войти",
            "signUpVia": "Зарегистрироваться с помощью"
        },
        social: {
            "fallbackText": "Мы выслали на почту корректные логин-пароль для входа в систему бронирования, если вы их не получили, свяжитесь с нашей службой поддержки",
            "successfulAuth": "Введите, пожалуйста, свое имя и фамилию, чтобы мы знали, как к вам обращаться"
        },
        redirect: "Редирект",
        file: 'Файл',
        telegram: 'Telegram'
    },
    inputs: {
        required: 'Поле обязательно для заполнения',
        pattern: 'Поле должно содержать заглавные буквы',
        cyrillicAndCapitalError: 'Поле должно содержать заглавные буквы на кириллице',
        maxLengthError: 'Максимум 10 символов',
        onlyCyrillicError: 'Используйте только кириллицу',
        onlyLatinError: 'Используйте только латиницу',
        formatError: 'Заполните поле в формате',
        errorDateFormat: 'ДД.ММ.ГГГГ',
        firstName: {
            label: 'Имя',
            placeholder: 'ИВАН'
        },
        lastName: {
            label: 'Фамилия',
            placeholder: 'ИВАНОВ'
        },
        phone: {
            label: 'Номер телефона',
            pattern: 'Заполните поле в формате +7 (XXX) XXX-XX-XX',
            wrong: 'Некорректный номер телефона'
        },
        sailingExperience: {
            label: 'Яхтенный опыт',
            required: 'Укажите ваш опыт',
            defaultValue: 'Ваш опыт:',
            '0/7': 'нет опыта',
            '1/7': 'знаю базовое устройство яхты',
            '2/7': 'умею настраивать паруса',
            '3/7': 'хожу всеми курсами и могу пришвартоваться',
            '4/7': 'совершенствую технику в деталях',
            '5/7': 'предвижу ветер и ситуации на воде',
            '6/7': 'готовлюсь к победе в гонках',
            '7/7': 'парусный мастер, изучаю стратегию и тактику',
            'travel0/7': 'никогда не был(а) на яхте',
            'travel1/7': 'дергал(а) шкоты, держал(а) руль',
            'travel2/7': 'участвовал(а) в гонках',
            'travel3/7': 'был(а) в регате',
            'travel4/7': 'шкипер с правами',
        },
        email: {
            label: 'Электронная почта',
            pattern: 'Введите существующий адрес email'
        },
        birthdate: {
            label: 'Дата рождения'
        },
        enterPromocode: {
            placeholderPromo: 'Введите промокод',
            placeholderCert: 'Введите сертификат',
            label: 'Промокод/Сертификат',
            placeholder: 'Введите промокод или сертификат',
            submitButton: 'Применить',
            wrongPromoOrCert: 'Промокод или сертификат недействителен',
            wrongPromo: 'Промокод недействителен',
            wrongCert: 'Сертификат недействителен',
            corpCertApplied: 'Применен корпоративный сертификат.',
            corpCertAppliedDesc: 'Для корпоративных клиентов не предусмотрены другие скидки.'
        },
        comment: {
            label: 'Комментарий',
            placeholder: 'Допишите в комментарий дополнительную информацию о вас или особое пожелание к поездке',
            travelPlaceholder: 'Укажите любые пожелания (например, ﻿к какому капитану вы хотите попасть или в какой экипаж).',
            maxLength: 'Комментарий слишком длинный, максимальная длина комментария 300 символов',
        },
        consents: {
            publicOffer: 'Согласен с публичной офертой',
            publicOfferError: 'Пожалуйста, подтвердите согласие с публичной офертой',
            validData: 'Согласен на обработку персональных данных',
            validDataError: 'Пожалуйста, подтвердите согласие на обработку персональных данных',
            returnPolicy: 'Согласен с условиями отмены и переноса',
            returnPolicyTrain: 'Тренировок',
            returnPolicyError: 'Пожалуйста, подтвердите согласие с условиями отмены и переноса тренировок',
            checkAge: 'Подтверждаю, что всем участникам больше {{checkAgeValue}} лет',
            checkAge2: '',
            pleaseCheckAgeValue: 'Пожалуйста, подтвердите, что всем участникам больше {{checkAgeValue}} лет. Если {{checkAgeValue}} лет нет, то приглашаем посетить ',
            pleaseCheckAgeValueEN: 'Пожалуйста, подтвердите, что всем участникам больше {{checkAgeValue}} лет.',
            ifHaveNoAge: 'Если участникам меньше {{checkAgeValue}}  лет, приглашаем посетить ',
            childrenClub: 'детский клуб',
            checkAgeSecondPart: ', где есть тренировки и занятия для детей от 7 лет.',
        },
        city: {
            label: 'Город',
            placeholder: 'Введите город',
        },
        region: {
            label: 'Область',
            placeholder: 'Введите область',
        },
        telegram: {
            pattern: 'Введите на латинице'
        },
        passportDate: {
            error: "Паспорт недействителен в период путешествия, укажите в поле номер паспорта 0000",
        },
        gender: {
            label: 'Пол',
            error: 'Укажите пол',
            female: 'Ж',
            male: 'М',
            none: '-',
        },
        passportDateForeign: {
            error: 'Паспорт недействителен в период путешествия, укажите в поле номер паспорта 0000'
        },
        shirtSize: {
            required: 'Выберите размер футболки',
        },
        flat: {
            label: 'Квартира',
            error: 'Для многоквартирного дома заполните номер квартиры',
            ownHouse: 'Живу в частном доме'
        },
        street: 'Улица',
        house: 'Дом',
        address: {
            label: 'Адрес проживания покупателя*',
            validate: 'Укажите страну и город',
            fullAddressValidate: 'Укажите полный адрес (страна, город, улица, дом)',
            placeholder: 'Адрес по месту жительства',
        },
        provideAddress: 'Укажите точный адрес',
        passportFile: 'Паспорт'
    },
    discounts: {
        coachless: 'скидка за запись без инструктора',
        fullBook: 'скидка за полную лодку',
        subscription: 'Скидка из абонемента',
        people: 'От {{peopleCount}} человек скидка',
        peopleFull: 'Скидка за бронирование от {{peopleCount}} человек',
        fullBookInstructor: 'скидка за лодку и без инструктора',
        discountLess: 'К сожалению, размер скидки уменьшится:',
        discountEqual: 'Размер скидки не изменился:',
        discountRise: 'Поздравляем, размер скидки увеличится:',
        discountCurrentOn: 'Ваш текущий абонемент на',
        discountWillUpOn: 'будет пополнен на',
        discountOnYachtLuch: 'Скидка на килевые яхты и групповые занятия на «Лучах» —',
        discountOnIndividualLuch: 'Скидка на индивидуальные занятия на «Лучах» —',
        discountOnRace: 'Скидка на гонки (кроме Кубков Силы ветра) —'
    },
    sport: {
        pluralizeBoats: 'лод',
        schedule: {
            selectors: {
                pickBase: 'Выберите базу',
                base: 'База',
                pickBoat: 'Выберите лодку',
                boat: 'Лодка',
                pickDate: 'Выберите дату',
                date: 'Дата',
                pickTime: 'Выберите время',
                time: 'Время',
                anyInstructor: 'Любой классный',
                instructorWarning: 'Мы стараемся учитывать ваши пожелания, но не всегда можем гарантировать присутствие конкретного инструктора.',
                availableSits: 'доступно мест',
                pickInstructor: 'Выберите инструктора',
                bookWholeBoat: 'Забронировать всю лодку',
                noEnoughSeatsToFull: 'Нет полностью свободных лодок',
                noVacantSeats: 'Места на выбранном мероприятии закончились, вы будете перенаправлены',
                bookWithoutInstructor: 'Записаться без инструктора',
                withoutInstructor: 'Без инструктора',
            }
        },
        membersScreen: {
            dontFillOthers: 'Не заполнять данные для остальных участников',
            startDate: 'Дата и время начала тренировки',
            subscriptionPayment: 'абонементом',
            subscriptionBalance: 'Баланс абонемента:',
            haveNoSubscription: 'У вас нет активного абонемента.',
            pay: 'Оплатить',
        },
        noPlaces: 'Нет Мест',
    },
    travel: {
        regataRegister: 'Вы всего в паре шагов от регистрации на регату!',
        pickMembersCount: 'Выберите количество участников',
        noPlaces: 'Мест нет',
        title: 'Путешествие',
        startPlace: 'Место старта',
        startDate: 'Дата старта',
        '20%': 'Хочу оплатить 20%',
        '20%_extra': ', а остальную часть - не позднее, чем за месяц до поездки. Обратите внимание, что при такой оплате частями стоимость путешествия увеличится на 7%.',
        '50%': 'Вы можете оплатить 50%',
        '50%_extra': ', а остальную часть — не позднее, чем за месяц до поездки. Обратите внимание, что при оплате частями стоимость путешествия увеличится на 5%.',
        '100%': 'Хочу оплатить 100%',
        'practice100%': 'Хочу оплатить 100%',
        arrivalDate: 'Дата поездки',
        include: 'Что входит в цену:',
        notInclude: 'Что не входит в цену:',
        freeSits: 'Свободных мест',
        firstName: {
            label: 'Имя',
            placeholder: 'ИВАН'
        },
        lastName: {
            label: 'Фамилия',
            placeholder: 'ИВАНОВ'
        },
    },
    paymentTimer: {
        expired: 'Время на оплату заказа истекло, требуется создать новый заказ',
        haveTime: 'У вас осталось {{time}}, для того чтобы произвести оплату'
    },
    practice: {
        label: 'Практика',
        register: 'Вы всего в паре шагов от регистрации на практику',
        '50%_extra': ', а остальную часть — не позднее, чем за месяц до поездки. Обратите внимание, что при оплате частями стоимость практики увеличится на 5%.',
    },
    errors: {
        placeLack: 'Недостаточно свободных мест',
        someoneAlready: 'Упс... Кто-то вас опередил и уже забронировал. Сейчас уже нет нужного количества мест. Попробуйте выбрать другое время.',
        expired: 'Упс... Время больше не доступно для бронирования. Попробуйте выбрать другое.',
        coachNotAvailable: 'У этого инструктора больше нет свободных мест.',
        phoneAlreadyUses: 'Телефон уже занят',
    },
    productContainer: {
        headerLabel: 'Бронирование'
    },
    headerMenu: {
        leave: 'Выйти',
        profile: 'Профиль'
    },
    school: {
        pickGroup: 'Выберите группу',
        group: 'Группа',
        place: 'Место',
        address: 'Адрес',
        freePlaces: 'Свободных мест',
        lecture: 'Лекция',
        '0/7': 'нет опыта',
        '1/7': 'ходил в качестве пассажира',
        '2/7': 'ходил в качестве члена экипажа, умею работать со шкотами, вязать узлы',
        '3/7': 'сам ходил в качестве рулевого несколько раз',
        '4/7': 'ходил много в качестве рулевого, есть опыт, нужны права',
    },
    bookingCard: {
        direction: 'Направление',
        temperature: 'Температура',
        air: 'Воздух',
        water: 'Вода',
        waterTime: 'Время на воде в день',
        discount: 'Скидка',
        members: 'Участники',
        price: 'Цена',
        free: 'БЕСПЛАТНО',
        from: 'от',
        to: 'до',
        outstandingPay: 'Осталось оплатить',
        difficultyLevel: 'Сложность',
        event: 'Событие',
        duration: 'Длительность',
        max: 'максимум',
        instructor: 'Инструктор',
        teacher: 'Преподаватель',
        name: 'Название',
        startTimeAt: 'НАЧАЛО ЗАНЯТИЯ В',
        place: 'Место проведения',
        h: 'Ч',
        m: 'М',
        title: 'Название',
        lecturer: 'Учитель',
    },
    helpers: {
        anHour: 'час',
        hours: 'часов',
        hours2: 'часа',
    },
    final: {
        processing: 'Ваша оплата еще находится в обработке. Ее статус обновится чуть позже.',
        eventSuccess: 'Вы успешно прошли регистрацию на событие! Не забудьте подготовить все нужные вещи к {{date}}!',
        tableEventSuccess: 'Вы успешно прошли регистрацию на событие! Не забудьте подготовить все нужные вещи к {{date}}!',
        materials: 'А пока можете ознакомиться с полезными материалами:',
        firstTrains: 'Первые парусные тренировки',
        takeOnTrain: 'Что брать с собой на тренировки',
        luchClothes: 'Как правильно одеться на «Лучи» и «Лазеры»',
        gloves: 'Перчатки для яхтинга: Испытание в бою',
        shoe: 'Эксперимент: какая обувь меньше скользит',
        onTheMain: 'На главную страницу',
        inCalendar: 'Добавить в календарь',
        inWhatCalendar: 'В какой календарь добавить событие?',
        bookMore: 'Забронировать еще',
        regattaSuccess: 'Вы успешно прошли регистрацию на регату. Приготовьте все необходимые вещи к {{date}}.',
        regattaSuccessRU:
            'Поздравляем, ваше бронирование подтверждено, и совсем скоро вы отправитесь в настоящее приключение. Мы сами в предвкушении!\n' +
            '\n' +
            'Скоро вам на почту придет письмо-подтверждение со всеми подробностями.\n' +
            'Если его нет, пожалуйста, проверьте спам и промоакции.\n' +
            '\n' +
            'Если у вас появятся вопросы, наш отдел заботы всегда на связи в telegram ',
        regattaSuccessBye: 'До встречи под парусом!',
        questions: 'Если у вас остались вопросы, связанные с&nbsp;покупкой путешествия, позвоните по телефону ',
        orContactEmail: ' или напишите нам на почту ',
        infoOnEmail: 'В письме мы пришлем подробную информацию о путешествии и как к нему подготовиться.',
        waitEmail: 'Ожидайте письмо на почту по адресу, который указали при регистрации.',
        paymentSuccess: ' успешно принята.',
        paymentProcessing: ' находится в обработке. Ее статус обновится чуть позже.',
        yourPayment: 'Ваша оплата за бронирование ',
        from: ' c ',
        to: ' на ',
        on: ' по ',
        soonWillEmail: 'Скоро мы пришлем письмо на почту, которую вы указали при бронировании, где подробно расскажем о предстоящем курсе.',
        before: 'Перед началом занятий советуем почитать статьи в нашем онлайн-журнале:',
        firstTravel: 'Инструкция для первого яхтенного путешествия',
        advices: 'Советы и воспоминания об учебе шкиперов Силы ветра',
        aboutYachtLicence: 'Все, что нужно знать о яхтенных правах',
        ifHaveQuestions: 'Если у вас есть вопросы, позвоните нам по телефону +7 495 108 15 03 или напишите на почту ',
        thanksForBook: 'Спасибо за бронирование! Ваш платеж {{places}} {{accusativePlaces}} на {{orderName}} ',
        practiceFrom: ' c ',
        inProcess: ' находится в обработке.',
        successPayment: 'Поздравляем! Вы успешно оплатили {{places}} {{dativePlaces}} на {{orderName}} c',
        howGetLicense: 'Как получить права и стать капитаном',
        youngYachtMen: 'Молодые яхтсмены о том, как стать капитаном',
        online: ' онлайн ',
        offline: ' офлайн ',
        schoolTo: ' по ',
        successTheory: 'Поздравляем! Вы успешно оплатили курс теории',
        beingProcessing: 'находится в обработке.',
        successBookLecture: 'Поздравляем! Вы успешно записались на лекцию',
        soonWillEmailLecture: 'Мы отправим электронное письмо со всеми подробностями предстоящей лекции на адрес, который вы указали при бронировании.',
        successPayLecture: 'Поздравляем! Вы успешно оплатили участие в лекции',
        joinTelegram: 'Обязательно вступите в группу в телеграме и зайдите на обучающую платформу',
        yourTG: 'Канал вашей группы в ТГ',
        learningPlatform: 'Обучающая платформа',
        code: 'Код для входа',
    },
    feedback: {
        poorly: 'Плохо',
        unsatisfactory: 'Неудовлетворительно',
        satisfactorily: 'Удовлетворительно',
        good: 'Хорошо',
        perfectly: 'Отлично',
        ratePlease: 'Оцените работу нашей системы бронирования',
        commentPlaceholder: 'Будем рады услышать пожелания, которые помогут нам сделать систему бронирования лучше',
        thanks: 'Спасибо за отзыв!',
        send: 'Отправить',
    },
    profile: {
        certificatesSubscriptions: {
            blockTitle: 'Сертификаты и абонементы',
            certificateLabel: 'Проверить сертификат',
            certificatePlaceholder: 'Введите сертификат',
            certificateNotFound: 'Сертификат не существует',
            certificateNotAvailable: 'Сертификат не активен',
            certificateBalance: 'Баланс сертификата',
            certificateApplies: 'Распространяется на',
            certificateCountry: 'Страна применения',
            certificateAvailableTo: 'Действителен до',
            check: 'Проверить',
            subscription: 'Абонемент',
            subscriptionBalance: 'Баланс абонемента',
            subscriptionDiscount: 'Скидка на килевые яхты и групповые лучи',
            subscriptionIndividualDiscount: 'Скидка на индивидуальные лучи',
            subscriptionDiscountRace: 'Скидка на гонки (кроме Кубков Силы ветра)',
            subscriptionEmpty: 'У вас нет активного абонемента',
            buy: 'Купить'
        },
        data: {
            phoneTaken: 'Этот номер уже занят.',
            blockTitle: 'Персональные данные',
            nameLabel: 'Имя',
            namePlaceholder: 'Иван',
            surnameLabel: 'Фамилия',
            surnamePlaceholder: 'Иванов',
            phoneLabel: 'Номер телефона',
            emailLabel: 'Электронная почта',
            experienceLabel: 'Яхтенный опыт',
            experienceDefaultLabel: 'Укажите ваш опыт',
            experience0: 'нет опыта',
            experience1: 'пару раз был(а) на тренировке',
            experience2: 'хожу по колдунчикам и делаю оверштаг',
            experience3: 'умею ходить против и по ветру',
            experience4: 'сам(а) вооружаю лодку и вяжу узлы',
            experience5: 'сам(а) швартуюсь',
            experience6: 'прохожу все участки дистанции',
            experience7: 'участвую в гонках в роли рулевого',
            success: 'Данные успешно обновлены',
            save: 'Сохранить',
            passportInternational: 'Заграничный паспорт',
            travel: 'путешествий',
            for: 'для',
            enNameLabel: 'Имя (латиницей)',
            enSurnameLabel: 'фамилия (латиницей)',
            passport: 'Паспорт - серия и номер',
            passportFiles: 'Паспортные данные',
            citizenship: 'Гражданство',
            citizenshipPlaceholder: 'Россия',
            unsubscribed_news: 'Отписаться от новостей'
        },
        exit: 'Exit',
        twofa: {
            modal: 'Подтвердите действие',
            placeholder: 'Код из сообщения на почте',
            submit: 'Подтвердить',
            resendAgain: 'Отправить заново через',
            resendSubmit: 'Отправить новый код',
        }
    },
    logout: 'Выход из учетной записи',
    notFound: {
        pageNotFound: 'Страница не найдена',
        backToMain: 'Вернуться на главную'
    },
    newPassword: {
        saveAndContinue: 'Сохранить и продолжить',
        description: 'Мы сбросили ваш старый пароль. Придумайте новый',
        label: 'Новый пароль',
        placeholder: 'С цифрами, а лучше и с символами'
    },
    tablet: {
        walk: 'Прогулки',
        sport: 'Спорт'
    },
    api: {
        usePhoneLogin: {
            unknownError: 'Что-то пошло не так',
            phoneLimit: 'Превышен лимит отправки СМС на номер телефона',
        },
        useApi: {
            notFound: 'Не найдено'
        }
    },
    constructor: {
        redirectText: 'После авторизации мы перенаправим вас на корпоративную страницу'
    },
    agreements: {
      personalData: 'https://silavetra.com/personal-data'
    },
    certificate: {
      headerLabel: 'Бронирование',
      label: 'Подарочный сертификат',
      ownerName: 'Имя и Фамилия',
      country: 'Страна',
      extra: 'Сертификат действует в течение одного года и не распространяется на продукты силы ветра другого типа (например, «яхтинг в городе» можно использовать при оплате тренировок, гонок, но не путешествий). обмену и возврату не подлежит.',
      price: 'Цена',
      wish: 'Пожелание',
      code: 'Код сертификата',
      productType: 'Тип продукта',
      form: {
        inputName: 'Укажите имя',
        inputNamePlaceholder: 'Имя',
        inputSurname: 'Укажите фамилию',
        inputSurnamePlaceholder: 'Фамилия',
        sender: 'отправителя',
        recipients: 'получателя',
        inputWish: 'Укажите пожелания',
        inputWishPlaceholder: 'Напишите пожелание...',
        inputWishMaxLength: 'Макс. количество символов: 45',
        inputEmail: 'Укажите почту',
        inputCurrency: 'Валюта',
        inputProductType: 'Выберите тип продукта',
        inputCountry: 'Выберите страну покупки',
        inputNominal: 'Укажите номинал сертификата',
        inputSum: 'Укажите сумму',
        inputSendToEmail: 'Отправить получателю по электронной почте',
        submit: 'Купить',
        description: 'До оформления подарочного сертификата осталась всего пара шагов. После оплаты вам будет доступен сам сертификат в электронном виде и уникальный код, по которому можно забронировать путешествие, место в Школе капитанов или на парусную тренировку.',
        inputPhone: 'Укажите номер телефона',
        inputSendersEmail: 'Укажите почту отправителя',
        success: {
          title: 'Ура, поздравляем! Отправили подарочный сертификат вам на почту — порадуйте своих друзей и близких!',
          certNumber: 'Номер вашего сертификата',
          downloadButton: 'Скачать сертификат',
          onTheMain: 'На главную страницу',
          orderOneMore: 'Оформить еще один',
        }
      },
    },
    subscriptions: {
      headerTitle: 'Абонемент',
      countryOfUsage: 'Страна применения',
      ownerEmail: 'Почта держателя',
      payWithCert: 'Оплатить сертификатом',
      enterCert: 'Введите сертификат',
      balance: 'Баланс',
      debit: 'Списание',
      publicOffer: 'https://silavetra.com/legal/sailing-pass',
      title: 'До оформления абонемента осталась всего пара шагов. После оплаты вы сможете использовать абонемент для оплаты тренировок на наших базах.',
      nominal: 'Номинал абонемента -',
      discountGroupLabel: 'Скидка на групповые лучи',
      discountKilLabel: 'Килевые яхты',
      discountRaceLabel: 'Гонки',
      discountIndividualLabel: 'На индивидуальные лучи',
      name: 'Имя',
      andSurname: 'и фамилия',
      price: 'Цена',
      amountOfAbonement: 'Укажите стоимость абонемента',
      certificateDiscount: 'скидка с сертификата',
      success: {
        title: 'Ура, поздравляем! Абонемент оформлен и готов к использованию — удачных занятий!',
        howToUse: 'Как использовать абонемент:',
        step1: '1. Войдите в свой аккаунт (с которого покупали абонемент).',
        step2: '2. Выберите желаемую тренировку.',
        step3: '3. На экране оплаты поставьте галочку «Оплатить абонементом».',
        step4: '4. С вашего абонемента спишется сумма за тренировку с учетом вашей скидки.',
        step5: '5. Вы можете просмотреть баланс вашего абонемента в личном кабинете или связаться с членом нашей команды.',
        redirect: 'Вы будете перенаправлены на оформление продукта через:',
        redirectSeconds: 'секунд',
        onMain: 'На главную страницу',
        toBooking: 'К бронированию'
      }
    },
    generic: {
      dropzone: {
        dropFile: 'Перенесите файл (или нажмите)',
        uploadFile: "Загрузите файл",
        formatTooLarge: 'Формат слишком большой',
        formatNotAccepted: 'Формат не поддерживается',
        invalidType: 'Неподдерживаемый тип файла',
        delete: 'Удалить'
      }
    },
    paymentPay: {
      methods: {
        card: 'Оплатить банковской картой',
        sub: 'Оплатить абонементом',
        cert: 'Оплатить сертификатом',
        cash: 'Оплатить наличными',
      },
      selectMethod: 'Выбор способа оплаты',
      noneActiveSub: 'У вас нет активных абонементов',
      notEnoughBonus: 'недостаточно баллов',
      bonusCount: 'баллов',
      remainder: 'Остаток к оплате',
      notEnoughMoney: 'недостаточно средств',
      promoDiscount: 'Скидка по промокоду',
      certBalance: 'Баланс сертификата',
      payExtra: 'Доплатить',
      payByCard: 'картой',
      payByCash: 'наличными',
      wrongPromo: 'Неправильный номер промокода',
      subscription: 'Абонемент',
    },
    orders: {
      statistic: {
        slots: 'Тренировки',
        arrivals: 'Приключения и Практики',
        groups: 'Теория и Лекторий',
        count: 'Кол-во покупок'
      }
    }
}