import React from 'react';
import ProductContainer from '../../common/ProductContainer';
import Steps from '../../layout/Steps/index2';
import { t } from 'i18next';
import Counter from '../../generic/Counter/index_2';
import Schedule from '../Schedule';
import Divider from '../../generic/Divider';
import Final from "../../adventure/Final";
import MembersCheckout from "../../adventure/MembersCheckout";
import MembersScreen from "../../adventure/MembersScreen";
import { observer } from 'mobx-react-lite';

const AdventureContainerUI = (props) => {
  const {
    resetData,
    query,
    onArrivalPick,
    arrivalURL,
    toNextStep,
    discount,
    cardProps,
    arrivals,
    selectedArrival,
    setSelectedArrival,
  } = props;

  return (
    <ProductContainer productType="travel" bookingCardProps={cardProps}>
      <Steps resetData={resetData}/>
      {query.step < 2 && (!!arrivals.length || !!selectedArrival) && <>
        <div className="book-title">
          {t('travel.regataRegister')}<br/> {t('travel.pickMembersCount')}
        </div>
        <Counter limit={selectedArrival?.available_reserved_sits}/>
        <Divider/>
        <Schedule updateData={onArrivalPick} onSubmit={toNextStep} />
      </>}
      {query.step === 2 && (selectedArrival?.id)
      && <MembersScreen
        peopleCount={query.people}
        finalize={toNextStep}
        orderId={query.orderId}
        step={query.step}
        arrivalURL={arrivalURL}
      />}
      {query.step === 3 && (selectedArrival?.id)
      && <MembersCheckout
        additionalDiscount={discount?.extraInfo !== 'loyal' ? discount : undefined}
      />}
      {query.step === 5 && <Final updateData={setSelectedArrival}/>}
    </ProductContainer>
  );
};

export default observer(AdventureContainerUI);