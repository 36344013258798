import React, {useEffect} from 'react';
import jwt_decode from "jwt-decode";
import {useApi} from "../../lib/api/useApi";

const LOGOUT_IDS = [28, 15, 12];

const RefreshToken = ({}) => {

    const {userLoggedIn, logout, userAccessToken} = useApi();

    //TODO Temporal solution for all current users to receive access token
    useEffect(() => {
        if(userAccessToken) {
            try {
                const decoded = jwt_decode(userAccessToken);
                console.log(decoded)
                if(decoded && decoded.id && LOGOUT_IDS.includes(decoded.id)) {
                    console.log("XXX")
                    logout()
                    window.location.reload();
                    return;
                }
            } catch (e) {

            }
        }

        if (userLoggedIn && !window.location.pathname.includes('/logout')) {
            //updateAccessToken();
        }
    }, [])

    return(
        <></>
    )
}

export default RefreshToken;
