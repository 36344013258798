import React from 'react';

const MMarineTest = () => {
    return (
        <>
            Test page!
        </>
    );
}

export default MMarineTest