import {useApi} from "./useApi";

export const GroupsApi = (isMMarine = false) => {
    const { rawRequest } = useApi();

    const getGroups = async (params) =>
        rawRequest('get', '/public/groups', {type: 'theory', ...params});

    const getGroupsMMarine = async (params) =>
        rawRequest('get', '/public/groups', {mmarine: true, type: 'theory', ...params});

    const getGroupByUrl = async (url) =>
        rawRequest('get', `/public/groups/${url}`);

    const checkGroupAvailability = async (groupId) => rawRequest('get', '/public/groups/available/' + groupId);

    return {
        getGroups: isMMarine ? getGroupsMMarine : getGroups,
        getGroupByUrl,
        checkGroupAvailability
    }
}
