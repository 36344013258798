import React, { useEffect, useState, useCallback } from "react";
import { useQueryParam, withDefault, NumberParam } from "use-query-params";
import { Col, Row, Container } from "reactstrap";
import Header from "../../layout/Header";
import BookingCard from "../BookingCard";
import SubscriptionForm from "../SubscriptionForm";
import SubscriptionFinal from '../SubscriptionFinal';
import { usePublicEndpoints } from "../../../lib/api/usePublicEndpoints";
import _ from 'lodash';
import {useLogin} from "../../../lib/utils/hooks/useLogin";
import { t } from 'i18next';
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from '../../../lib/models/global';

const SubscriptionContainer = () => {
    const globalM = useInjection(GlobalModelStoreToken);
    const {isUserLoggedIn} = useLogin();

    const [subscription, setSubscription] = useState({});
    const [price, setPrice] = useQueryParam("price", NumberParam);
    const [step, setStep] = useQueryParam("step", withDefault(NumberParam, 1));
    const [email, setEmail] = useState("");
    const [payingUser, setPayingUser] = useState(null)
    const [order, setOrder] = useState({});
    const [orderId, setOrderId] = useQueryParam("orderId", NumberParam);
    const { getOrder, getUserCleanFields, getCurrencies } = usePublicEndpoints();
    const [currencies, setCurrencies] = useState([]);
    const [certificateApplied, setCertificateApplied] = useState(null);
    const [entity, setEntity] = useState({});

    const debouncedHandleInput = useCallback(_.debounce((subscription) => setPrice((+subscription.price_value)), 200), []);
    const setValues = (subscription) => {
        setSubscription(() => subscription);
        debouncedHandleInput(subscription);
    };

    const globalState = globalM.get();

    useEffect(() => {
        setEntity(_.isEmpty(subscription) ? order.subscription : subscription);
    }, [order, subscription])

    useEffect(() => {
        if (!isUserLoggedIn) {
            setStep(1)
            setOrderId(undefined)
            setOrder({})
        }
    }, [step])

    useEffect(() => {
        getCurrencies()
            .then(res => setCurrencies(res))
        setEmail(() => globalState.userEmail);
        if (globalState.userEmail)
            getUserCleanFields()
                .then(res => setPayingUser(res))
                .catch(e => console.log(e))
        if (orderId) {
            getOrder(orderId)
                .then(res => {
                    setOrder(res)
                })
        }
    }, []);
    return (
        <Container className={"pl-0 pr-0"}>
            <Row className={"min-vh-100 no-gutters"}>
                <Col xs={12} lg={6} className={"form-col"}>
                    <Header label={t('subscriptions.headerTitle')} />
                    {step === 1 &&
                        <SubscriptionForm
                            setSubscription={(subscription) => setValues(subscription)}
                            email={email}
                            subscription={subscription}
                            price={price}
                            userSubscriptionId={payingUser?.subscription_type_id}
                            depositSum={payingUser?.deposit_sum}
                            currencies={currencies}
                            setCertificateApplied={setCertificateApplied}
                            certificateApplied={certificateApplied}
                            order={order}
                            entity={entity}
                        />
                    }
                    {step === 2 &&
                        <SubscriptionFinal />
                    }
                </Col>
                <Col xs={6} className={"offset-sm-6 card-col min-vh-100 fixed-top d-none d-lg-block"}>
                    <BookingCard
                        entity={entity}
                        payingUser={payingUser}
                        order={order}
                        currencies={currencies}
                        certificateApplied={certificateApplied}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default SubscriptionContainer;
