import React from "react";
import SVLogo from "../../../styles/assets/images/sv/sv_logo.svg";
import WRLogoBlack from "../../../styles/assets/images/menu/WRLogoBlack.svg";
import {isRus} from "../../../lib/utils/country";

const MMarineLogo = "https://booking.sila-vetra.bask.ws/static/media/m-marine.b20a2c93.webp";

const Logo = ({isMMarine}) => {
    const logo = isRus ? SVLogo : WRLogoBlack
    return (
        <div className="login-logo">
            {isMMarine
                ? <img src={MMarineLogo} alt=""/>
                : <img src={logo} alt=""/>}
        </div>
    )
}

export default Logo