import {useApi} from "./useApi";

export const LecturesApi = (isMMarine = false) => {
    const { rawRequest } = useApi();

    const getLectures = async (params) =>
        rawRequest('get', '/public/groups', {type: 'lecture', ...params});

    const getLecturesMMarine = async (params) =>
        rawRequest('get', '/public/groups?', {mmarine: true, type: 'lecture', ...params});

    return {
        getLectures: isMMarine ? getLecturesMMarine : getLectures,
    }
}
