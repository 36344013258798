import Counter from "../../generic/Counter/index_2";
import {pluralizeWord} from "../../../lib/utils/helpers";
import React, {useMemo} from "react";
import i18next from "i18next";
import {ICurrency} from "../../../lib/utils/types";

//@ts-ignore
const WrappedCounter = ({step, selectedSlot, currencies, eventData, selectedEvent, event}) => {
    const {t, language} = i18next;

    const fullBookingOnly = useMemo(() => selectedSlot?.team_only || eventData?.coachless_discount?.active, [selectedSlot?.team_only, eventData?.coachless_discount])
    const isShowCounter = step < 2
            && (event && eventData?.boat?.places || !event)
            && (!eventData?.slot?.single_booking && !selectedEvent?.single_booking);

    return <>
        {isShowCounter && (
                <div className="row no-gutters counter-container justify-content-between">
                    <div className="col-xs-12 col-md-7">
                        <Counter limit={eventData?.slot?.available_sits} outerClassName={'sport'}
                                 stepSize={ fullBookingOnly && eventData?.boat?.places ? +eventData.boat?.places : 0}
                                 pluralizer={(capacity) => pluralizeWord(t('sport.pluralizeBoats'), capacity, language)}/>
                    </div>
                    {!!eventData?.slot?.full_book_discount_size && eventData?.boat?.places &&
                            <div className={`col col-auto d-flex full-book-label align-items-center pr-3 pb-2 ${eventData.full_book_discount?.active ? 'text-success' : 'text-muted'}`}>
                                {`${t('discounts.people', {peopleCount: eventData?.boat?.places})} ${eventData.slot?.full_book_discount_size} `}
                                {eventData.slot?.full_book_discount_type === 'percent' ? '%' : currencies.find((currency: ICurrency) => currency.key === eventData.priceCurrency)?.sign}
                            </div>
                    }
                </div>
        )}
    </>
}

export default WrappedCounter;