import React from 'react';
import {Redirect, Route} from 'react-router';
import {useApi} from '../../lib/api/useApi';
import { observer } from 'mobx-react-lite';
import { useInjection } from 'brandi-react';
import { AuthModelStoreToken } from '../../entities/auth/model';
import { GlobalModelStoreToken } from '../../lib/models/global';

const forbidden = ['/login', '/logout'];
const neededForMMarine = ['/connect/'];
const tabletPage = '/tablet'

const _Route = ({
                    isPublic,
                    isAny,
                    path,
                    exact,
                    component: Component,
                    location,
                    isMMarine,
                    ...rest
                }) => {

    const authM = useInjection(AuthModelStoreToken);
    const globalM = useInjection(GlobalModelStoreToken);
    const {userLoggedIn, getGlobalState} = useApi();
    const globalState = getGlobalState();
    const _globalState = globalM.get();

    const isForbiddenRedirect = forbidden.find(x => location.pathname.startsWith(x));

    if (!isPublic && !isAny && !userLoggedIn && !authM.isLoggedIn()) {
        if (isForbiddenRedirect) {
            return <Redirect to={{ pathname: (isMMarine ? '/m' : '') + '/login' }}/>;
        } else {
            return <Redirect to={{ pathname: (isMMarine ? '/m' : '') + '/login', search: '?redirect=' + encodeURIComponent(location.pathname + location.search) }}/>;
        }
    }

    if (((globalState.clientRole || _globalState.clientRole) !== 'tablet' && location.pathname.startsWith(tabletPage)))
        return <Redirect to={{ pathname: '/' }}/>;

    if (((globalState.clientRole || _globalState.clientRole) === 'tablet' && !location.pathname.startsWith(tabletPage) && !isForbiddenRedirect))
        return <Redirect to={{ pathname: tabletPage }}/>;


    if (isPublic && userLoggedIn && !neededForMMarine.some(x => location.pathname.startsWith(x))) {
        return <Redirect to={{ pathname: '/', state: { prev: location.pathname, query: location.search } }}/>;
    }

    return (
        <Route path={path} exact={exact}>
            <Component {...rest} isMMarine={isMMarine} />
        </Route>
    );
};

export default observer(_Route);