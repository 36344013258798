import React, {useState, useEffect} from 'react';
import {localizeDate, getWeekDay, normalizeDate, weekdaysLocalized} from '../../../../lib/utils/format';
import './style.scss';
import i18next from "i18next";

const DateSelector = ({dates, orderId, disabled, isDataEntered, selectedDate, setPickedDate, handleDropdownClick, isDropdownActive, preloadEntity, setIsSlotOverbooked}) => {
  const [date, setDate] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleClick = (e) => {
    const {id} = e.currentTarget;
    setDate(() => id);
    setDropdownVisible(() => false);
    setPickedDate(id);
  } 
  useEffect(() => {
    setDropdownVisible(isDropdownActive);
  }, [isDropdownActive]);
  useEffect(() => {
    if (preloadEntity === 'slot' || isDataEntered || orderId) {
        const dateValue = selectedDate || dates?.[0];
        setDate(() => dateValue);
    } else {
        setDate(() => null);
    }
}, [dates, preloadEntity]);
  const handleDropdown = () => {
    if (preloadEntity === 'slot') return;
    setDropdownVisible(isDropdownVisible => !isDropdownVisible);
    handleDropdownClick('date');
    setIsSlotOverbooked(false);
  }
  const {t, language} = i18next;

  const dateList = dates?.map((item, index) => <li className="selector__item" id={item} key={index} value={localizeDate(item, language)} onClick={handleClick}>{localizeDate(item, language)} | {weekdaysLocalized[language]?.[getWeekDay(item)]}</li>)
  return (
    <>
      <span className={disabled ? 'd-none' : ''}>{preloadEntity !== 'slot' ? t('sport.schedule.selectors.pickDate') : t('sport.schedule.selectors.date')}</span>
      <div className={disabled ? 'selector d-none' : 'selector'}>
        <div className={preloadEntity !== 'slot' ? "selector__trigger" : "selector__trigger disabled"} onClick={handleDropdown}>
            {date ? `${localizeDate(date, language)} | ` : t('sport.schedule.selectors.pickDate')}
            {weekdaysLocalized[language]?.[getWeekDay(normalizeDate(date), language)] || ''}
        </div>
        <ul className={isDropdownVisible ? "selector__list" : "selector__list d-none"}>
          {dateList}
        </ul>
      </div>
    </>
  )
}

export default DateSelector;