import React from 'react';
import { Container, } from 'reactstrap';
import { NavLink } from "react-router-dom";
import FormButton from "../../generic/buttons/main";

const MMarineContainer = () => {

    return (
        <Container className={'pl-0 pr-0'}>
            Hello from m-marine!
            <NavLink to="/m-marine/test">
                <FormButton value="На тестовую страницу" />
            </NavLink>
        </Container>
    );
}

export default MMarineContainer