import React, {useCallback, useEffect} from 'react';
import FormButton from '../../generic/buttons/main/index2';
import {regexes, trimOnChange} from '../../../lib/utils/helpers';
import { Col, Row, Input, Label } from 'reactstrap';
import {useForm} from "react-hook-form";
import "./style.scss";
import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import 'react-phone-input-2/lib/style.css'
import PhoneField from '../../common/PhoneField'
import {BooleanParam, NumberParam, StringParam} from "use-query-params";
import {
  COUNTRIES_LIST,
  CERTIFICATE_CURRENCIES_INFO, DEFAULT_COUNTRY, COUNTRY, PRODUCT_TYPES_LIST_ADAPTER,
} from '../../../lib/utils/constants';
// @ts-ignore
import {useLocation} from "react-router-dom";
import {useApi} from "../../../lib/api/useApi";
import {useLogin} from "../../../lib/utils/hooks/useLogin";
import {useRedirect} from "../../../lib/utils/hooks/useRedirect";
// @ts-ignore
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import {useOrdersApi} from "../../../lib/api/useOrdersApi";
import {usePaymentsApi} from "../../../lib/api/usePaymentsApi";
import {TUserFields} from "../../../lib/api/useTypedPublicEndpoint";
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
// @ts-ignore
import {CURRENCIES_KEYS, CURRENCIES_KEYS_LOCAL, DEFAULT_CURRENCY} from "sv-common/constants/currencies";
import RangeInput from "../../common/RangeInput";
import _ from "lodash";
import {ICertificateCurrency} from "../../../lib/utils/types";
import {useLoaderState} from "../../../lib/api/loaderState";
import i18next from 'i18next';

const PRODUCT_OPTIONS_LIST = PRODUCT_TYPES_LIST_ADAPTER[COUNTRY]
    .filter((productType) => productType.key !== PRODUCT_TYPES.SUBSCRIPTIONS)

type TStateToUse = { [key: string]: any } | null

const CertificateRegister = ({submitHandler}: {submitHandler?: (data: any) => void}) => {
    const { t } = i18next;
    const form = useForm({ mode: "onChange" });
    const { register, errors, handleSubmit, getValues, control, setValue, clearErrors } = form;
    const { createOrder } = useOrdersApi();
    const { createPayment } = usePaymentsApi();
    const { getGlobalState } = useApi();
    const { ensureLoggedIn } = useLogin();
    const { pathname } = useLocation();
    const values = getValues();
    const {errorPageRedirect} = useRedirect();

    const {setIsLoading, isLoading} = useLoaderState();

    const paymentHandler = (orderId: number, stateToUse?: TStateToUse) =>
            errorPageRedirect(() => createPayment(orderId, pathname + `?orderId=${orderId}&step=2`, stateToUse)
                .then((res) => (window.location.href = res.url))
            )

    const submitForm = () =>
        ensureLoggedIn((isUserAlreadyLoggedIn: boolean, user: TUserFields, stateToUse: TStateToUse) => {
            setIsLoading(true);
            // TODO заменить контролирумые элементы на неконтролируемые по необходимости
            const preparedData = {
                assigneeName: toName, assigneeSurname: toSurname, assigneePhone: toPhone, assigneeEmail: toEmail,
                comment, sendersEmail: senderEmail, currency, productType: selectedProducts, country, value: certificateSum,
                sendEmail: sendRecipient
            }

            const participant = {email: toEmail};
            const payloadData = {
                participants: [participant],
                product: 'certificates',
                ...preparedData
            };
            if (submitHandler) {
                return submitHandler(payloadData)
            }
            return createOrder(payloadData, stateToUse)
                .then(res => {
                    paymentHandler(res.orderId, stateToUse)
                })
        })

    const globalState = getGlobalState();

    const [certificateSum, setCertificateSum] = useQueryParamWithDefault<number>("certificateSum", NumberParam, 1000);
    const [toName, setToName] = useQueryParamWithDefault<string>("name", StringParam, '');
    const [toSurname, setToSurname] = useQueryParamWithDefault<string>("surname", StringParam, '');
    const [toPhone, setToPhone] = useQueryParamWithDefault<string>("phone", StringParam, '');
    const [toEmail, setToEmail] = useQueryParamWithDefault<string>("email", StringParam, '');
    const [senderEmail, setSenderEmail] = useQueryParamWithDefault<string>("senderEmail", StringParam, globalState.userEmail);
    const [currency, setCurrency] = useQueryParamWithDefault<string>("currency", StringParam, DEFAULT_CURRENCY[COUNTRY]);
    const [selectedProducts, setSelectedProducts] = useQueryParamWithDefault<string>("productTypes", StringParam, "");
    const [comment, setComment] = useQueryParamWithDefault<string>("comment", StringParam, "");
    const [country, setCountry] = useQueryParamWithDefault<string>("country", StringParam, DEFAULT_COUNTRY[COUNTRY] );
    const [sendRecipient, setSendRecipient] = useQueryParamWithDefault<boolean>("sendRecipient", BooleanParam, false);

    const debouncedHandleInput = useCallback(_.debounce((value) => setCertificateSum(value), 200), [])

    const currencyData:ICertificateCurrency = CERTIFICATE_CURRENCIES_INFO.find((cur: ICertificateCurrency) => cur['key'] === currency)!

    useEffect(() => {
        if(certificateSum >= currencyData?.min_range && certificateSum <= currencyData?.max_range)
            clearErrors('sertificate_sum')
    }, [certificateSum])

    return (
        <>
            <form className="certificate-register-container" onSubmit={handleSubmit(submitForm)}>
                <div className="certificate-desctiption p-3">
                  {t('certificate.form.description')}
                </div>
                <div className="inputs">
                    <Row>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <Label className="mt-2 input-label" htmlFor="name">
                                {t('certificate.form.inputName')} <br /> {t('certificate.form.recipients')}:
                                {(errors.name || values.name) && <span className="validation-img"><img src={errors.name ? invalid : valid} alt="" /></span>}
                            </Label>
                            <Input
                                placeholder={String(t('certificate.form.inputNamePlaceholder'))}
                                name="name"
                                defaultValue={toName}
                                onChange={(e) => { setToName(e.target.value) }}
                                type="text"
                                innerRef={register({ required: true })}
                            />
                            {errors.name?.type === 'required' &&
                                <span className="error-label">
                                    {t('inputs.required')}
                                </span>
                            }
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <Label className="mt-2 input-label" htmlFor="surname">
                                {t('certificate.form.inputSurname')} <br /> {t('certificate.form.recipients')}:
                                {(errors.surname || values.surname) && <span className="validation-img"><img src={errors.surname ? invalid : valid} alt="" /></span>}
                            </Label>
                            <Input
                                placeholder={String(t('certificate.form.inputSurnamePlaceholder'))}
                                name="surname"
                                defaultValue={toSurname}
                                id="surname"
                                onChange={(e) => { setToSurname(e.target.value) }}
                                type="text"
                                innerRef={register({ required: true })}
                            />
                            {errors.surname?.type === 'required' &&
                                <span className="error-label">
                                    {t('inputs.required')}
                                </span>
                            }
                        </Col>
                        <Col xs={12} className={'control-wrapper'}>
                            <Label className="mt-2 input-label" htmlFor="comment">{t('certificate.form.inputWish')}:</Label>
                            <textarea defaultValue={comment} placeholder={String(t('certificate.form.inputWishPlaceholder'))} name="comment" id="comment"
                                      onChange={e => setComment(e.target.value)} ref={register({maxLength: 45})} />
                            <span className={errors.comment ? "error-label" : "error-label d-none"}>
                                {errors.comment?.type === "maxLength" ? t('certificate.form.inputWishMaxLength') : ""}
                            </span>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <Label className="mt-2 input-label" htmlFor="senderEmail">
                              {t('certificate.form.inputSendersEmail')}:
                                {(errors.senderEmail || values.senderEmail) && <span className="validation-img"><img src={errors.senderEmail ? invalid : valid} alt="" /></span>}
                            </Label>
                            <Input
                                placeholder="username@email.com"
                                name="senderEmail"
                                id="senderEmail"
                                defaultValue={senderEmail}
                                onChange={e => {
                                    setSenderEmail(trimOnChange(e))
                                }}
                                type="text"
                                innerRef={register({ required: true, pattern: regexes.emailRegex })}
                            />
                            <span className={errors.senderEmail ? "error-label" : "error-label d-none"} role="alert">
                                {errors.senderEmail?.type === "required" ? t('inputs.required') : ""}
                                {errors.senderEmail?.type === "pattern" ? t('inputs.email.pattern') : ""}
                            </span>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <Label className="mt-2 input-label" htmlFor="currency">{t('certificate.form.inputCurrency')}</Label>
                            <select defaultValue={currency} id={`currency`} name={`currency`} onChange={(e) => {
                                const country = CERTIFICATE_CURRENCIES_INFO.find((cur: ICertificateCurrency) =>
                                        cur['key'] === e.target.value
                                )!.country_key

                                setCurrency(e.target.value)
                                setCountry(country)
                                setValue('country', country)
                            }} ref={register({ required: true })}>
                              {Object.values<string>(CURRENCIES_KEYS_LOCAL[COUNTRY]).map(i => (
                                <option value={i}>{i}</option>
                              ))}
                            </select>
                            <span className={errors.currency ? "error-label" : "error-label d-none"} role="alert">
                                {errors.currency?.type === "required" ? t('inputs.required') : ""}
                            </span>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                          <Label className="mt-2 input-label" htmlFor="productTypes">
                            {t('certificate.form.inputProductType')}
                          </Label>
                          <select id={`productTypes`} name={`productTypes`} ref={register({ required: true })}
                                  onChange={(e) => setSelectedProducts(e.target.value)}
                                  defaultValue={selectedProducts} >
                              <option key={'none'} value={""} disabled selected>{t('certificate.form.inputProductType')}</option>
                            {PRODUCT_OPTIONS_LIST.map(productType => (
                              <option key={productType.key} value={productType.key}>{productType.label}</option>
                            ))}
                          </select>
                          <span className={errors.productTypes ? "error-label" : "error-label d-none"} role="alert">
                            {errors.productTypes?.type === "required" ? t('inputs.required') : ""}
                          </span>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <Label className="mt-2 input-label" htmlFor="country">
                                {t('certificate.form.inputCountry')}
                            </Label>
                            <select id={`country`} name={`country`} ref={register({ required: true })} defaultValue={country}
                                    onChange={(e) => {
                                        const currency = CERTIFICATE_CURRENCIES_INFO.find((cur: ICertificateCurrency) =>
                                                cur['country_key'] === e.target.value
                                        )!.key

                                        setCountry(e.target.value)
                                        setCurrency(currency)
                                        setValue('currency', currency)
                                    }} >
                                {COUNTRIES_LIST[COUNTRY].map(country => (
                                    <option key={country.key} value={country.key}>{country.label}</option>
                                ))}
                            </select>
                            <span className={errors.country ? "error-label" : "error-label d-none"} role="alert">
                                {errors.country?.type === "required" ? t('inputs.required') : ""}
                            </span>
                        </Col>
                        <Col xs={12} className={'control-wrapper'}>
                            <Row>
                                <Col md="8">
                                    <RangeInput
                                        name="rangeCertificateSum"
                                        maxRange={currencyData?.max_range}
                                        minRange={currencyData?.min_range}
                                        defaultValue={certificateSum}
                                        form={form}
                                        stepSize={currencyData?.step_size}
                                        onChange={value => {
                                            setValue('sertificate_sum', value)
                                            debouncedHandleInput(value)
                                        }}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Label className="mt-2 input-label" htmlFor="sertificate_sum">{t('certificate.form.inputSum')}:</Label>
                                    <Input
                                        type="number"
                                        defaultValue={certificateSum}
                                        min={currencyData?.min_range}
                                        max={currencyData?.max_range}
                                        className="sertificate_sum"
                                        name="sertificate_sum"
                                        id="sertificate_sum"
                                        onChange={e => {
                                            setValue('rangeCertificateSum', ~~e.target.value)
                                            debouncedHandleInput(e.target.value)
                                        }}
                                        innerRef={register({
                                            required: `Минимальный номинал: ${currencyData?.min_range} ${currencyData?.sign}`,
                                            min: {
                                                value: currencyData?.min_range,
                                                message: `Минимальный номинал: ${currencyData?.min_range} ${currencyData?.sign}`
                                            },
                                            max: {
                                                value: currencyData?.max_range,
                                                message: `Максимальный номинал: ${currencyData?.max_range} ${currencyData?.sign}`
                                            }
                                        })}
                                    />
                                </Col>
                                <Col xs="12">
                                    <div>
                                        <span className={errors['sertificate_sum'] ? "error-label" : "d-none"}>
                                            {errors['sertificate_sum']?.message}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12">
                            <div className="checkout__input-wrapper">
                                <Input
                                    type="checkbox"
                                    id="sendRecipient"
                                    name="sendRecipient"
                                    // @ts-ignore
                                    value={sendRecipient}
                                    onChange={e => setSendRecipient(e.target.checked)}
                                />
                                <Label htmlFor="sendRecipient">
                                    {t('certificate.form.inputSendToEmail')}
                                </Label>
                            </div>
                        </Col>
                        {sendRecipient && <>
                            <Col xs={12} md={6} className={'control-wrapper mt-2'}>
                                <PhoneField
                                        label={`${t('certificate.form.inputPhone')} ${t('certificate.form.recipients')}:`}
                                        phoneValue={toPhone}
                                        control={control}
                                        error={errors[`phone`]}
                                        customOnChange={setToPhone}
                                />
                                <input type="hidden" name="toPhone" defaultValue={toPhone} ref={register({ required: true, minLength: 11 })} />
                                <span className={errors[`phone`] ? "error-label" : "error-label d-none"} role="alert">
                                    {errors[`phone`]?.type === "required" ? t('inputs.required') : ""}
                                    {errors[`phone`]?.type === "pattern" ? t('inputs.phone.pattern') : ""}
                                </span>
                            </Col>
                            <Col xs={12} md={6} className={'control-wrapper'}>
                                <Label className="mt-2 input-label" htmlFor="email">
                                    {t('certificate.form.inputEmail')} <br /> {t('certificate.form.recipients')}:
                                    {(errors.email || values.email) && <span className="validation-img"><img src={errors.email ? invalid : valid} alt="" /></span>}
                                </Label>
                                <Input
                                        placeholder="username@email.com"
                                        defaultValue={toEmail}
                                        name="email"
                                        id="email"
                                        onChange={e => {
                                            setToEmail(trimOnChange(e))
                                        }}
                                        type="text"
                                        innerRef={register({ required: true, pattern: regexes.emailRegex })}
                                />
                                <span className={errors.email ? "error-label" : "error-label d-none"} role="alert">
                                    {errors.email?.type === "required" ? t('inputs.required') : ""}
                                    {errors.email?.type === "pattern" ? t('inputs.email.pattern') : ""}
                                </span>
                            </Col>
                        </>}
                        <FormButton value={t('common.buy')} type="submit" disabled={isLoading} role="submitButton"/>
                    </Row>
                </div>
            </form>
        </>
    )
}

CertificateRegister.propTypes = {};

export default CertificateRegister

