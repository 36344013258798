// @ts-ignore
import getCountryValue from '../../../lib/utils/getCountryValue';

export const GoogleCLType = 'google';
export const YandexCLType = 'yandex';
export const OutlookCLType = 'outlook';

export const CLTypes = [GoogleCLType, YandexCLType, OutlookCLType];

export const getCLTypes = () => {
  const types = [GoogleCLType, OutlookCLType];

  const yandexValue = getCountryValue({ ru: YandexCLType });
  yandexValue && types.push(yandexValue);

  return types;
}

export interface ICalendarEventsProps {
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
  name: string,
}

export type TObjectHandler = (name: string, start: string, end: string) => JSX.Element