import "./style.scss";
import Logo from "../layout/ Logo";
import PropTypes from 'prop-types';
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {PaymentsApi} from "../../lib/api/usePaymentsApi";
import {useCaptcha} from "../../lib/utils/hooks/useCaptcha";

const Error = ({customText, isMMarine, isPaymentError}) => {
    const {orderId} = useParams();
    const [generateCaptcha, CaptchaDiv] = useCaptcha();

    const {getActualInvoice} = PaymentsApi();

    useEffect(() => {
        if(isPaymentError)
            generateCaptcha((captcha) => {
                getActualInvoice(orderId, captcha).then(res => window.location.href = res.url)
            })
    }, [])

    return (
        <div id="login" className="overlay d-flex justify-content-center flex-column login">
            {isPaymentError && CaptchaDiv}
            <div className="login-container error-container">
                <Logo isMMarine={isMMarine}/>
                <div className="error">
                    <p>
                        {customText || "Упс. Кажется, у нас что-то сломалось, и\u00A0мы уже бежим чинить это. " +
                            "Дайте нам немного времени и возвращайтесь чуть позже."}
                    </p>
                    Если у вас есть вопросы, напишите нам на <a href="mailto:allo@silavetra.com">allo@silavetra.com</a>, и
                    мы обязательно ответим.
                </div>
            </div>
        </div>
    )
}

Error.propTypes = {
    customText: PropTypes.string,
    isMMarine: PropTypes.bool,
    isPaymentError: PropTypes.bool
}

export default Error