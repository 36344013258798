import PropTypes from 'prop-types';
import {Input, Label} from "reactstrap";
import {t} from "i18next";
import React from "react";
import {COUNTRY} from "../../../lib/utils/constants";
import ElementWithAccessInCountries from "../../../lib/utils/hocs/ElementWithAccessInCountries";

const AgeConsent = ({register, age = 16, error, value, required = false}) => {
    const getAgeConsentError = (age) => {
        if (COUNTRY === 'EN') {
            return t('inputs.consents.pleaseCheckAgeValueEN', {checkAgeValue: age})
        }
        else {
            return <span className="error-text__ageConsent">
            {t('inputs.consents.pleaseCheckAgeValue', {checkAgeValue: age})}
                <a href="https://silavetra.com/kids">{t('inputs.consents.childrenClub')}</a>
                {t('inputs.consents.checkAgeSecondPart')}
        </span>
        }
    }

    return <div className="checkout__input-wrapper radio-input">
        <Input
            type="checkbox"
            id="ageConsent"
            data-testid='ageConsent'
            name="ageConsent"
            innerRef={register({
                required
            })}
        />
        <Label htmlFor="ageConsent">
            {t('inputs.consents.checkAge', {checkAgeValue: age})}
        </Label>
        <span className={error ? "error-label" : "error-label d-none"}>
            {error?.type === "required"
                ? getAgeConsentError(age)
                : ""}
        </span>
        {!value && !error &&
          <span className="default-text__ageConsent">
            {t('inputs.consents.ifHaveNoAge', {checkAgeValue: age})}
            <ElementWithAccessInCountries hideInCountries={['EN']}>
              <>
                <a href="https://silavetra.com/kids">{t('inputs.consents.childrenClub')}</a>
                {t('inputs.consents.checkAgeSecondPart')}
              </>
            </ElementWithAccessInCountries>
          </span>
        }
    </div>
}

AgeConsent.propTypes = {
    age: PropTypes.number,
    value: PropTypes.bool,
    error: PropTypes.object,
    required: PropTypes.bool,
    register: PropTypes.func.isRequired,
}

export default AgeConsent